import { IonList } from '@ionic/react';
import React from 'react';
import { ProductItem } from '../ProductItem/ProductItem';


interface ItemProps {
    products: Product[],
    onSelection?: Function
}

export const ProductList: React.FC<ItemProps> = ({ products, onSelection }) => {


    return (
        <>
            <IonList onSelect={product => onSelection ? onSelection(product) : {}} lines="full">
                {products.map(product => <ProductItem key={product.id} product={product}></ProductItem>)}
            </IonList> 
        </>
    );
};