import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, hourglass, hourglassOutline, hourglassSharp, listCircleOutline, listCircleSharp, logOut, menuOutline, menuSharp, peopleOutline, peopleSharp, settingsOutline, settingsSharp } from 'ionicons/icons';
import './Menu.css';
import { AppContext } from '../../AppContextProvider';
import { Plugins } from '@capacitor/core';
import AuthService from '../../shared/services/AuthService';
import LocalStorageService from '../../shared/services/LocalStorageServce';

const { Modals, Storage } = Plugins;

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Ordini in arrivo',
    url: '/orders',
    iosIcon: archiveOutline,
    mdIcon: archiveSharp
  },
  // {
  //   title: 'Storico degli ordini',
  //   url: '/orders-history',
  //   iosIcon: hourglassOutline,
  //   mdIcon: hourglassSharp
  // },
  {
    title: 'Gestione prodotti',
    url: '/catalog',
    iosIcon: menuOutline,
    mdIcon: menuSharp
  },
  {
    title: 'Gestione categorie',
    url: '/categories',
    iosIcon: listCircleOutline,
    mdIcon: listCircleSharp
  },
  // {
  //   title: 'Lista utenti',
  //   url: '/users',
  //   iosIcon: peopleOutline,
  //   mdIcon: peopleSharp
  // },
  // {
  //   title: 'Configurazione',
  //   url: '/settings',
  //   iosIcon: settingsOutline,
  //   mdIcon: settingsSharp
  // },
];


const Menu: React.FC = () => {
  const location = useLocation();

  const globalState = useContext(AppContext);
  const { dispatch } = globalState;
  const { state } = globalState;


  const logout = () => {
    AuthService.logout().then(() => {
      dispatch({ type: 'REMOVE_AUTH' });
      LocalStorageService.clearToken();
      Storage.remove({ key: 'token' });
      Storage.remove({ key: 'user' });
      Modals.alert({
        title: 'Conferma',
        message: 'Il log-out è stato effettuato con successo'
      });
    });
  };


  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Amministrazione</IonListHeader>
          <IonNote></IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList>
          <IonListHeader>Profilo</IonListHeader>
          <IonItem onClick={()=>logout()} lines="none">
            <IonIcon slot="start" icon={logOut} />
            <IonLabel>Log-out</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
