import request from '../request'
import { Plugins } from '@capacitor/core';
const { Modals } = Plugins;

export interface Credentials {
    email?: string | undefined | null,
    password?: string | undefined | null,
    device_name?: string | undefined | null
};

export interface RegistrationFields {
    email?: string | undefined | null,
    password?: string | undefined | null,
    first_name?: string | undefined | null,
    last_name?: string | undefined | null,
    device_name?: string | undefined | null
};

interface AuthResponse {
    user: User,
    token: string

}


function login(credentials: Credentials) {
    return request({
        url: `/admin/auth/login`,
        method: 'POST',
        data: credentials
    });
}

function resetPassword(email: string) {
    return request({
        url: `/auth/password-reset`,
        method: 'POST',
        data: { email: email }
    }).then(() => {
        showMessage('Richiesta inviata', 'Controlla la casella e-mail e segui le istruzioni per resettare la password.');
    }).catch(() => {
        showMessage('Errore', 'Impossibile resettare la password. Controlla i campi immessi.');
    });
}

function logout() {

    return request({
        url: `/auth/logout`,
        method: 'POST'
    });

}

const showMessage = async (title: string, message: string) => {
    let alert = await Modals.alert({
        title: title,
        message: message
    });
}

const AuthService = {
    login, resetPassword, logout
}

export default AuthService;