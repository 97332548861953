import request from '../request'


function getNextSlots(mode: string) {
    return request({
        url: `order/mode/${mode}/slots/next`,
        method: 'GET'
    });
}

function list(params: any = null) {
    return request({
        url: `admin/order`,
        method: 'GET',
        params: params
    });
}

function get(id: string | number) {
    return request({
        url: `admin/order/${id}`,
        method: 'GET'
    });
}

function create(order: any) {
    return request({
        url: `admin/order`,
        method: 'POST',
        data: order
    });
}

function update(id: number, params: any) {
    return request({
        url: `admin/order/${id}`,
        method: 'PUT',
        data: params
    });
}


const OrderService = { getNextSlots, get, list, create, update };
export default OrderService;