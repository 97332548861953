import { IonGrid, IonRow, IonCol, IonImg, IonText } from '@ionic/react';
import React from 'react';

import './ImagePlaceholder.css';

interface ContainerProps {
    text: string;
    image: string;
    sizeLg?: string
    size?: string
}

const ImagePlaceholder: React.FC<ContainerProps> = ({ text, image, size, sizeLg }) => {

    return (
        <>
            <IonGrid className="ion-text-center">
                <IonRow className="ion-justify-content-center">
                    <IonCol size={size ?? "12"} sizeLg={sizeLg ?? "6"}>
                        <IonImg className="image" src={"/assets/" + image + ".svg"}></IonImg>
                        <IonText><p className="ion-margin-top">{text}</p></IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
};

export default ImagePlaceholder;
