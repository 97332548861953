import React, { useContext, useEffect, useState, useRef } from 'react';

import { IonCol, IonGrid, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonList, IonRow, IonText } from '@ionic/react';
import Moment from 'react-moment';
import { AppContext } from '../../../AppContextProvider';
import './OrderItem.css';
import { trash } from 'ionicons/icons';
import OrderService from '../../../shared/services/OrderService';

interface ItemProps {
    order: Order
}


export const OrderItem: React.FC<ItemProps> = ({ order }) => {

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const [orderObject, setOrderObject] = useState<Order>(order);

    const updateOrderStatus = (status: string) => {
        OrderService.update(order?.id, { order_status_id: status }).then((data) => {
            setOrderObject(data);
        });
        parent.current.close();
    }

    const parent = useRef<any>(null);
    useEffect(() => { }, [orderObject]);

    return (
        <IonItemSliding ref={parent}>
            <IonItemOptions side="end">
                {state.tenant.order_statuses
                    .filter((status: any) => status?.id !== orderObject?.status?.id)
                    .map((status: any) => <IonItemOption onClick={() => updateOrderStatus(status?.id)} color={(status?.type.key == 'Cancelled') ? 'danger' : ((status?.type.key == 'Complete') ? 'success' : 'warning')} >{status?.name}</IonItemOption>)}
            </IonItemOptions>
            <IonItem routerLink={'/order/' + orderObject.id} className={"item-with-status " + orderObject.status?.type.key}>
                <IonGrid>
                    <IonRow>
                        <IonCol size="2">
                            <IonText>
                                #{orderObject.id}
                                <p className="order-status-text">{orderObject.status?.name}</p>
                            </IonText>
                        </IonCol>
                        <IonCol className="ion-text-center" size="3">
                            <IonText className="ion-text-center">
                                {(orderObject?.source !== 0 && order?.source !== 1) ? orderObject.user?.first_name + ' ' + orderObject.user?.last_name : ''}
                            </IonText>
                        </IonCol>
                        <IonCol size="3" className="ion-text-center">
                            <IonText className="ion-text-center">
                                {orderObject.total.formatted}
                            </IonText>
                        </IonCol>
                        <IonCol size="4">
                            <IonText className="ion-text-center">
                                <h2><Moment format="HH:mm">{orderObject.datetime}</Moment></h2>
                                <p><Moment format="DD/MM/Y">{orderObject.datetime}</Moment></p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonItem>
        </IonItemSliding>
    );
};
