import React from 'react';
import { IonRadioGroup } from '@ionic/react';
import RadioItem from './RadioItem';

interface ContainerProps {
    component: ProductComponent,
    selectionAction: Function
}

const RadioGroup: React.FC<ContainerProps> = ({ component, selectionAction }) => {

    // const defaultOption = component.options?.filter(o => o.default)[0];
    // selectionAction(Array(defaultOption));

    const optionFromId = (id: number) => {
        return component.options?.filter(o => String(o.id) === String(id))[0];
    };

    return (
        <IonRadioGroup  onIonChange={(e) => { selectionAction(Array(optionFromId(e.detail.value))); }}>
            {component.options?.map(option =>
                <RadioItem key={option.id} option={option} ></RadioItem>
            )}
        </IonRadioGroup>
    );
};

export default RadioGroup;
