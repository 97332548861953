import React, { useContext, useEffect } from 'react';
import { IonButton, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonNote, IonIcon, IonToggle, IonList } from '@ionic/react';
import { useState } from 'react';


interface ContainerProps {
    confirmAction: Function,
    category?: Category
}

const CategoryEditor: React.FC<ContainerProps> = ({ confirmAction, category }) => {

    console.log('category to update:', category);
    const [updatedCategory, setUpdatedCategory] = useState<any>(category ?? { name: "", category: "" });
    const [validForm, setValidForm] = useState<boolean>(false);

    const updateCategory = (field: string, value: any) => {
        let prod: any = updatedCategory; prod[field] = value;
        setUpdatedCategory(prod);
        checkFields();
    }
    const checkFields = () => {
        let valid = true;
        if (!updatedCategory.name || updatedCategory.name === '' || updatedCategory.description === '') valid = false;
        setValidForm(valid);
        return valid;
    }
    const submit = () => {
        if (checkFields()) {
            const updatedBody = updatedCategory;
            confirmAction(updatedBody);
        }
    }



    useEffect(() => {

    }, []);


    return (
        <IonList>
            <IonItem>
                <IonLabel position="stacked">Nome</IonLabel>
                <IonInput value={updatedCategory.name} onIonChange={(e) => updateCategory('name', e.detail.value)} type="text"></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Descrizione</IonLabel>
                <IonInput value={updatedCategory.description} onIonChange={(e) => updateCategory('description', e.detail.value)} type="text"></IonInput>
            </IonItem>
            <IonButton disabled={!validForm} className="ion-margin-bottom" expand="block" onClick={() => { submit(); }}>Salva</IonButton>
        </IonList>
    );
};

export default CategoryEditor;