import React, { useEffect } from 'react';
import { IonRow, IonCol, IonText, IonButton, IonHeader, IonToolbar, IonTitle, IonGrid, IonImg, IonModal } from '@ionic/react';
import './MessageModal.css';


interface ModalProps {
    cta: string,
    text: string,
    image: string,
    ctaAction: Function,
    show: boolean
}
interface ContainerProps {
    modalProps: ModalProps | undefined
}


const MessageModal: React.FC<ContainerProps> = ({ modalProps }) => {


    return (
        <>
            {(modalProps !== undefined &&
                <IonModal cssClass="modal" isOpen={modalProps?.show}>
                    <IonGrid className="ion-text-center">
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeLg="6" className="ion-justify-content-center">
                                <IonImg className="image" src={"/assets/" + modalProps.image + ".svg"}></IonImg>
                                <IonText color="dark">
                                    <p className="ion-margin-top">{modalProps.text}</p>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonButton className="cta-button" color="primary" expand="block" onClick={() => { modalProps.ctaAction(); }}>{modalProps.cta}</IonButton>
                </IonModal>
            )}
        </>
    );
};

export default MessageModal;
