import React, { useContext, useEffect } from 'react';
import { IonButton, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonNote, IonIcon, IonToggle, IonList } from '@ionic/react';
import { useState } from 'react';
import { informationCircleOutline } from 'ionicons/icons';
import { adjustPriceFormat } from '../../../../helpers';
import { State } from 'ionicons/dist/types/stencil-public-runtime';
import { AppContext } from '../../../../AppContextProvider';


interface ContainerProps {
    confirmAction: Function,
    product: Product,
    categories: Category[]
}

const ProductInfoEditor: React.FC<ContainerProps> = ({ confirmAction, product, categories }) => {

    const [updatedProduct, setUpdatedProduct] = useState<any>({ ...product, price: adjustPriceFormat(product.price.amount), full_price: adjustPriceFormat(product.full_price.amount) });
    console.log('updated: ', updatedProduct, 'original: ', product);
    const [validForm, setValidForm] = useState<boolean>(false);
    const [showDiscount, setShowDiscount] = useState<boolean>(product.full_price.amount > 0);
    const globalState = useContext(AppContext);
    const { state } = globalState;

    const updateProduct = (field: string, value: any) => {
        let prod: any = updatedProduct; prod[field] = value;
        setUpdatedProduct(prod);
        checkFields();
    }
    const checkFields = () => {
        let valid = true;
        if (!updatedProduct.name || !updatedProduct.description || !updatedProduct.kitchen_category || !updatedProduct.category_id || !updatedProduct.price) valid = false;
        setValidForm(valid);
        return valid;
    }
    const submit = () => {
        if (checkFields()) {
            const updatedBody = updatedProduct;
            confirmAction(updatedBody);
        }
    }



    useEffect(() => {

    }, [product]);


    return (
        <IonList>
            <IonItem>
                <IonLabel position="stacked">Nome</IonLabel>
                <IonInput value={updatedProduct.name} onIonChange={(e) => updateProduct('name', e.detail.value)} type="text"></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Descrizione</IonLabel>
                <IonInput value={updatedProduct.description} onIonChange={(e) => updateProduct('description', e.detail.value)} type="text"></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Categoria</IonLabel>
                <IonSelect value={updatedProduct.category.id} onIonChange={(e) => updateProduct('category_id', e.detail.value)}>
                    {(categories.map((category: any) =>
                        <IonSelectOption key={category.id} value={category.id}>{category.name}</IonSelectOption>)
                    )}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Categoria Comanda</IonLabel>
                <IonInput value={updatedProduct.kitchen_category} onIonChange={(e) => updateProduct('kitchen_category', e.detail.value)} type="text"></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Componibile</IonLabel>
                <IonSelect value={updatedProduct.componible} onIonChange={(e) => updateProduct('componible', e.detail.value)}>
                    <IonSelectOption value={false}>No</IonSelectOption>
                    <IonSelectOption value={true}>Sì</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Disponibile per</IonLabel>
                <IonSelect multiple value={updatedProduct.available_modes} onIonChange={(e) => updateProduct('available_modes', e.detail.value)}>
                    {state.tenant.available_modes.map((mode: any) =>
                        <IonSelectOption key={mode.enum} value={mode.enum}>{mode.name}</IonSelectOption>
                    )}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Prezzo finale (€)</IonLabel>
                <IonInput value={updatedProduct.price} onIonChange={(e) => updateProduct('price', e.detail.value)} type="number" min="0"></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>Mostra uno sconto</IonLabel>
                <IonToggle checked={showDiscount} onIonChange={(e) => { setShowDiscount(e.detail.checked); if (!e.detail.checked) updateProduct('full_price', 0); }}></IonToggle>
            </IonItem>
            <IonItem hidden={!showDiscount}>
                <IonLabel position="stacked">Prezzo barrato (€)</IonLabel>
                <IonInput value={updatedProduct.full_price} onIonChange={(e) => updateProduct('full_price', e.detail.value)} type="number" min="0"></IonInput>
                <IonNote onClick={() => { alert('Questo valore verrà mostrato come scontato.'); }} slot="end"><IonIcon icon={informationCircleOutline} /></IonNote>
            </IonItem>
            <IonButton disabled={!validForm} className="ion-margin-bottom" expand="block" onClick={() => { submit(); }}>Salva informazioni</IonButton>
        </IonList>
    );
};

export default ProductInfoEditor;