import React from 'react';
import { IonItem, IonText, IonNote, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList } from '@ionic/react';
import './OrderDetailCard.css';
import Moment from 'react-moment';

interface ContainerProps {
    order?: Order
}

const OrderDetailCard: React.FC<ContainerProps> = ({ order }) => {


    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Riepilogo</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonList>
                    <IonItem>
                        <IonText><strong>Totale</strong></IonText>
                        <IonNote slot="end">{order?.total.formatted}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonText>Data</IonText>
                        <IonNote slot="end"><Moment format="DD/MM/Y">{order?.datetime}</Moment></IonNote>
                    </IonItem>
                    <IonItem>
                        <IonText>Orario</IonText>
                        <IonNote slot="end"><Moment format="HH:mm">{order?.datetime}</Moment></IonNote>
                    </IonItem>
                    <IonItem>
                        <IonText>Modalità</IonText>
                        <IonNote slot="end">{order?.mode}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonText>Pagamento</IonText>
                        <IonNote slot="end">{order?.payment_method}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonText>Generato il</IonText>
                        <IonNote slot="end"><Moment format="DD/MM/Y HH:mm">{order?.created_at}</Moment></IonNote>
                    </IonItem>
                </IonList>
            </IonCardContent>
        </IonCard>
    );
};

export default OrderDetailCard;
