import { IonBadge, IonButton, IonCol, IonGrid, IonImg, IonItem, IonList, IonNote, IonRow, IonText, IonThumbnail } from '@ionic/react';
import React from 'react';
import Moment from 'react-moment';
import './ProductItem.css';

interface ItemProps {
    product: Product
}

export const ProductItem: React.FC<ItemProps> = ({ product }) => {

    return (
        <IonItem routerLink={'/product/' + product.id}>
            <IonThumbnail slot="start">
                <IonImg src={product.cover_image_url ?? 'https://dummyimage.com/200x200/dbdbdb/dbdbdb.png'} />
            </IonThumbnail>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="3">
                        <IonText className="ion-text-center">
                            {product.name}
                        </IonText>
                    </IonCol>
                    <IonCol className="ion-text-center">
                        <IonBadge>{product.category.name}</IonBadge>
                    </IonCol>
                    <IonCol>
                        <IonText className="ion-text-center">
                            {product.price.formatted} {(product.full_price && product.full_price?.amount > product.price.amount) && (<span className="crossed-text">{product.full_price?.formatted}</span>)}
                        </IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonItem>
    );
};