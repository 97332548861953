import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel, IonList, IonItemDivider, IonButton, IonModal, IonText, IonButtons } from '@ionic/react';
import React, { useState, useContext } from 'react';
import AuthService, { Credentials } from '../../shared/services/AuthService';
import { Plugins } from '@capacitor/core';
import LocalStorageService from '../../shared/services/LocalStorageServce';
import { AppContext } from '../../AppContextProvider';


const { Modals, Storage, Device, Browser } = Plugins;

interface ItemProps {
    onAccess: Function
}

export const LoginComponent: React.FC<ItemProps> = ({ onAccess }) => {

    const [credentials, setCredentials] = useState<Credentials>({});
    const [validForm, setValidForm] = useState<boolean>(false);

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    console.log('state:', state);

    let device_name: string;
    const device_info = Device.getInfo().then((i) => {
        device_name = (i.name) ?? i.model;
    });

    const updateCredentials = (credential: 'email' | 'password', value: string | undefined | null) => {
        let cred: Credentials = credentials; cred[credential] = value;
        setCredentials(cred);
        checkFields();
    }
    const checkFields = () => {
        let valid = true;
        if (!credentials?.email || !credentials.password) valid = false;
        setValidForm(valid);
        return valid;
    }
    const submit = () => {
        if (checkFields()) {

            AuthService.login({ email: credentials.email, password: credentials.password, device_name: device_name }).then((data) => {
                console.log('ON LOGIN', data);
                if (data && data.user) {
                    dispatch({ 'type': 'SET_AUTH', 'payload': { token: data.token, user: data.user } });
                    storeAuth(data);
                    onAccess();
                }
            });
        }
    }

    const storeAuth = async (data: any) => {
        Storage.set({
            key: 'token',
            value: data.token
        });
        Storage.set({
            key: 'user',
            value: JSON.stringify(data.user)
        });
        LocalStorageService.setToken(data.token);
    }

    const showForgotModal = async () => {
        await Browser.open({ url: 'https://' + state.tenant.domain + '/forgot-password', presentationStyle: 'popover' });
    }

    return (
        <IonList lines="full" className="ion-margin ion-padding">
            <IonItem>
                <IonLabel position="stacked">E-mail</IonLabel>
                <IonInput onIonChange={(e) => updateCredentials('email', e.detail.value)} type="email"></IonInput>
            </IonItem>

            <IonItem>
                <IonLabel position="stacked">Password</IonLabel>
                <IonInput onIonChange={(e) => updateCredentials('password', e.detail.value)} type="password"> </IonInput>
            </IonItem>
            <IonButton disabled={!validForm} onClick={() => { submit(); }} className="ion-margin-top" expand="block">Accedi</IonButton>
            <IonButton onClick={() => { showForgotModal(); }} className="ion-margin-top" fill="clear" color="dark" expand="block">Password dimenticata?</IonButton>
        </IonList>
    );
};