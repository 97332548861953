import React from 'react';
import { IonItem, IonText, IonNote, IonButton, IonButtons, IonIcon, IonToolbar } from '@ionic/react';
import './OrderPageToolbar.css';
import { filter, qrCode, add } from 'ionicons/icons';

import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner';


interface ContainerProps {
    onCompleteScan: Function,
    onFilterButtonClick: Function,
    onCreateButtonClick: Function
}

const OrderPageToolbar: React.FC<ContainerProps> = ({ onCompleteScan, onCreateButtonClick, onFilterButtonClick }) => {



    const openScan = () => {
        QRScanner.prepare()
            .then((status: QRScannerStatus) => {
                if (status.authorized) {
                    // camera permission was granted
                    // start scanning
                    let scanSub = QRScanner.scan().subscribe((text: string) => {
                        QRScanner.hide(); // hide camera preview
                        scanSub.unsubscribe(); // stop scanning
                        onCompleteScan(text);
                    });
                } else if (status.denied) {
                    // camera permission was permanently denied
                    // you must use QRScanner.openSettings() method to guide the user to the settings page
                    // then they can grant the permission from there
                } else {
                    // permission was denied, but not permanently. You can ask for permission again at a later time.
                }
            })
            .catch((e: any) => console.log('Error is', e));
    }


    return (
        <IonToolbar>
            {/* <IonButtons slot="start">
                <IonButton>
                    <IonIcon slot="start" icon={filter} />
                            Filtri
                        </IonButton>
                <IonButton onClick={() => openScan()}>
                    <IonIcon slot="start" icon={qrCode} />
                            Scansiona
                        </IonButton>
            </IonButtons> */}
            <IonButtons slot="end">
                <IonButton onClick={()=>onCreateButtonClick()}>
                    <IonIcon slot="end" icon={add} />
                            Crea nuovo
                        </IonButton>
            </IonButtons>
        </IonToolbar>
    );
};

export default OrderPageToolbar;
