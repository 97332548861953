import { Modals } from '@capacitor/core';
import { IonButton, IonToolbar, IonTitle, IonButtons, IonLabel, IonIcon, IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import { addCircle, pencil, trash } from 'ionicons/icons';
import React from 'react';
import ProductComponentService from '../../../../shared/services/ProductComponentService';
import { ComponentOptionItem } from './ComponentOptionItem';

interface ItemProps {
    component: ProductComponent,
    editComponentAction: Function,
    editComponentOptionAction: Function,
    onDelete: Function
}

export const ComponentItem: React.FC<ItemProps> = ({ component, editComponentAction, editComponentOptionAction, onDelete }) => {


    const deletePrompt = () => {
        const prompt = Modals.confirm({ title: "Conferma eliminazione", message: "Sei sicuro di voler eliminare il componente? Non potrai più recuperarlo", okButtonTitle: "Elimina", cancelButtonTitle: "Annulla" })
            .then((e) => { if (e.value) deleteComponent(component.id as string); });
    };

    const deleteComponent = (id: string | number) => {
        ProductComponentService.destroy(id).then(() => {
            onDelete();
        });
    }

    return (
        <IonCard key={component.id}>
            <IonCardHeader>
                <IonToolbar className="ion-text-left">
                    <IonTitle className="ion-text-left">{component.name}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill="clear" onClick={() => editComponentAction(component)} slot="start"><IonIcon icon={pencil} /></IonButton>
                        <IonButton fill="clear" onClick={() => deletePrompt()} color="danger" slot="end"><IonIcon icon={trash} /></IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonLabel>{(component.multiple) ? 'Selezione multipla' : 'Singola scelta'} - {(component.required) ? 'Obbligatorio' : 'Non obbligatorio'}</IonLabel>
            </IonCardHeader>
            <IonCardContent className="ion-text-center ion-justify-content-center">
                {component.options?.map(option => (<ComponentOptionItem editComponentOptionAction={(option: any) => editComponentOptionAction(option, component)} onDelete={onDelete} key={option.id} option={option} />))}
                <IonButton onClick={() => editComponentOptionAction(null, component)} className="ion-margin-top"><IonIcon slot="start" icon={addCircle} />Aggiungi opzione</IonButton>
            </IonCardContent>
        </IonCard>
    );
};