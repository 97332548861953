import React from 'react';
import { IonItem, IonText, IonNote, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonAvatar, IonButton, IonButtons, IonIcon, IonLabel, IonToolbar } from '@ionic/react';
import './OrderUserDetailCard.css';
import Moment from 'react-moment';
import { logoWhatsapp, mail, call } from 'ionicons/icons';

interface ContainerProps {
    user?: User
}

const OrderUserDetailCard: React.FC<ContainerProps> = ({ user }) => {


    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Cliente</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem className="avatar-item">
                    <IonAvatar className="profile-avatar" slot="start">
                        <img src={(user?.profile_photo_url) ?? '/assets/avatar.svg'} />
                    </IonAvatar>
                    <IonLabel>
                        <h1 className="user-name">{user?.first_name} {user?.last_name}</h1>
                        <h4>{user?.email}</h4>
                    </IonLabel>
                </IonItem>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton routerLink={"/user/" + user?.id}>Vai al profilo</IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton><IonIcon icon={call} slot="start"></IonIcon></IonButton>
                        <IonButton><IonIcon icon={logoWhatsapp} slot="start"></IonIcon></IonButton>
                        <IonButton><IonIcon icon={mail} slot="start"></IonIcon></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonCardContent>
        </IonCard>
    );
};

export default OrderUserDetailCard;
