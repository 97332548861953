import moment from 'moment';


//TODO: Refactor and scaffold classes per domain!!

export const formattedPrice = (price: number) => {
    return String((price / 100).toFixed(2)).replace('.', ',');
}

export const formatTime = (date: Date) => {
    return moment(date).format("HH:mm");
}

export const formatDate = (date: Date) => {
    return moment(date).format("DD/MM/Y");
}

export const formatDateTime = (date: Date) => {
    return moment(date).format("DD/MM/Y HH:mm");
}

//create an unique hash for each order item
export const orderHashes = (order: any) => {
    return order.items?.map((i: any) => String(i.hash));
}

export const countOrderItem = (order: any, item: any) => {
    let filtered = order.items.filter((it: any) =>
        String(it.hash) === hashItem(item));
    if (filtered.length > 0) {
        return filtered[0].quantity;
    }
    return 0;
}

export const countOrderProduct = (order: any, product: Product) => {
    let filtered = order.items.filter((it: any) => String(it.product_id) === String(product.id)).map((i: any) => i.quantity);
    return filtered.reduce((firstQuantity: any, secondQuantity: any) => firstQuantity + secondQuantity, 0);
}

export const calculateOrderSubTotal = (order: any) => {
    const filtered = order.items.filter((it: any) => it.quantity >= 1).map((i: any) => i.total);
    return filtered.reduce((firstTotal: any, secondTotal: any) => firstTotal + secondTotal, 0);
};

export const pushOrUpdateItem = (order: any, item: any) => {

    //TODO: Refactoring!!
    let hashes = orderHashes(order);
    const count = hashes.filter((h: any) => String(h) === String(item.hash)).length;

    if (count > 0) {
        if (item.product.componible) {
            item.quantity = (item.quantity > 0) ? item.quantity + 1 : order.items[hashes.indexOf(item.hash)].quantity + 1;
            item.total = item.total * item.quantity;

        } else {
            item.quantity = (item.quantity > 0) ? item.quantity : order.items[hashes.indexOf(item.hash)].quantity + 1;
        }
        order.items[hashes.indexOf(item.hash)] = item;

    } else {
        item.quantity = (item.quantity > 0) ? item.quantity : count + 1;
        order.items.push(item);
    }
}

export const hashItem = (item: any) => {
    return item.product_id + '_' + item.product_component_options.map((o: any) => o.id).join('_');
}

export const optionsFromCart = (cart: any) => {
    return [].concat.apply([], cart.map((c: any) => c.selectedOptions));
}

export const componentsTotal = (cart: any) => {
    const prices = optionsFromCart(cart).filter((o: any) => Number(o.price.amount) !== NaN).map((o: any) => o.price.amount);
    return prices.reduce((a: any, b: any) => Number(a) + Number(b), 0);
}

export const removeProduct = (order: any, product: any) => {
    let products = filterPerProduct(order, product).map((i: any) => String(i.product_id));
    order.items = order.items.filter((item: any) => {
        return products.indexOf(String(item.product_id)) === -1;
    });
}

export const removeItem = (cart: any, item: any) => {
    cart.items = cart.items.filter((filteringItem: any) => {
        return String(item.hash) !== String(filteringItem.hash);
    });
}

export const productTotal = (cart: any, product: any) => {

    let totals = filterPerProduct(cart, product).map((i: any) => i.total);
    return totals.reduce((a: any, b: any) => Number(a) + Number(b), 0);
}

export const filterPerProduct = (order: any, product: any) => {

    return order.items.filter((it: any) => String(it.product_id) === String(product.id));

}

export const prepareOrder = (order: any) => {

    return {
        "uid": order.uid,
        "mode": order.mode.enum,
        "datetime": order.datetime,
        "payment_method": order.payment_method,
        "total": order.total,
        "notes": order.notes,
        "items": order.items.map((item: any) => {
            return {
                ...item,
                option_ids: item.product_component_options.map((opt: any) => opt.id)
            }
        })
    };

}

export const adjustPriceFormat = (price: number) => {
    return (price / 100).toFixed(2);
}
