import React, { useState, useContext, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonSearchbar, IonButton, IonIcon, IonModal, IonSelect, IonSelectOption, IonSegment, IonSegmentButton, IonGrid, IonCol, IonRow, IonText, IonToast, useIonViewDidEnter, IonBackButton, IonButtons, IonProgressBar } from '@ionic/react';
import './NewOrderPage.css';
import { basketOutline } from 'ionicons/icons';
import MenuService from '../../../shared/services/MenuService';
import { countOrderItem, formattedPrice, pushOrUpdateItem, removeProduct, productTotal, calculateOrderSubTotal, removeItem } from '../../../helpers';
import { AppContext } from '../../../AppContextProvider';
import Moment from 'react-moment';
import ProductList from '../../../components/Order/NewOrder/List/List/ProductList';
import ImagePlaceholder from '../../../components/Placeholders/ImagePlaceholder/ImagePlaceholder';
import QuantityModal from '../../../components/Modals/QuantityModal/QuantityModal';
import ComponibleModal from '../../../components/Modals/ComponibleModal/ComponibleModal';
import Skeleton from '../../../components/Placeholders/Skeleton/Skeleton';


const NewOrderPage: React.FC = () => {


    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const [visibleCategories, setVisibleCategories] = useState<any>([]);
    const [availableCategories, setAvailableCategories] = useState<any>([]);
    const [showSelectionModal, setShowSelectionModal] = useState<boolean>(false);
    const [showComponentsModal, setShowComponentsModal] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [searching, setSearching] = useState<boolean>(false);
    const [productsLoaded, setProductsLoaded] = useState<boolean>(false);

    const [minOrder, setMinOrder] = useState<number>(0);


    const selectProduct = (product: Product) => {
        switch (product.componible) {
            case true:
                selectOptions(product);
                break;
            case false:
                selectQuantity(product);
                break;
        }
    };

    const selectQuantity = (product: Product) => {
        let item: any = { product: product, product_id: product.id, product_component_options: [] };
        item.quantity = countOrderItem(state.order, item);
        setSelectedProduct(item);
        setShowSelectionModal(true);
    }

    const selectOptions = (product: Product) => {
        let item: any = { product: product, product_id: product.id, product_component_options: [] };
        item.quantity = countOrderItem(state.order, item);
        setSelectedProduct(item);
        setShowComponentsModal(true);
    }

    const addItemToOrder = (item: any) => {
        let updatedOrder = state.order;

        if (item.quantity === 0 && !item.product.componible) {
            removeItem(updatedOrder, item);
        } else {
            pushOrUpdateItem(updatedOrder, item);
        }
        updatedOrder.total = calculateOrderSubTotal(updatedOrder);
        dispatch({ type: 'SET_ORDER', payload: updatedOrder });
    }


    const deleteProduct = (product: any) => {
        let updatedOrder = state.order;
        const tot = productTotal(updatedOrder, product);
        removeProduct(updatedOrder, product);
        updatedOrder.total = calculateOrderSubTotal(updatedOrder);
        dispatch({ type: 'SET_ORDER', payload: updatedOrder });
    }

    const loadMenu = () => {
        MenuService.getMenu(state.order.mode ? state.order.mode?.key : '').then((data) => {
            const categories = data.menu;
            setAvailableCategories(categories);
            setVisibleCategories(categories);
            setMinOrder(data.min_order_amount);
            setProductsLoaded(true);
        });
    }

    const init = () => {
        loadMenu();
    }


    useEffect(() => {
        //console.log('updated current order item length: ', state.order.items.length);
    }, [state]);


    const onModeChange = async (event: any) => {
        loadMenu();
    };

    useIonViewDidEnter(() => {
        init();
    });


    const filterProducts = (keyword: string) => {
        let filteredProducts = [];

        if (keyword !== '') {
            setSearching(true);
            const mapped = availableCategories.map((obj: any) => {
                return obj.products;
            });
            const filtered = [].concat.apply([], mapped).filter((prod: any) => {
                return String(prod['name']).toLowerCase().indexOf(keyword) !== -1;
            });
            filteredProducts = [
                {
                    id: 0,
                    name: "Risultati della ricerca",
                    products:
                        filtered
                }
            ];
        } else {
            setSearching(false);
            filteredProducts = availableCategories;
        }
        setVisibleCategories(filteredProducts);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle color="primary">Nuovo ordine</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonToast
                position="top"
                color="primary"
                isOpen={state.order.items.length > 0 && state.order.total < minOrder}
                message={"Ordine minimo non raggiunto"}
            />
            {(state.order.items.length > 0 &&
                <div className="checkout-button">
                    <IonButton routerLink="order/checkout/cart" type="button" size="default" expand="block" color="primary" shape="round" >
                        <IonIcon slot="start" color="white" name={basketOutline}></IonIcon> Riepilogo - {formattedPrice(state.order.total)}€
          </IonButton>
                </div>)}
            <IonContent>
                {!productsLoaded && (<IonProgressBar type="indeterminate"></IonProgressBar>)}
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle color="primary" size="large">Nuovo ordine</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {/* <DetailsToolbar onModeChange={(e: any) => onModeChange(e)} onSlotChange={() => { }}></DetailsToolbar> */}
                {
                    availableCategories.length > 0 ?

                        (<>
                            <IonSearchbar type="search" onIonInput={(e: any) => filterProducts(e.target.value.toLowerCase())} placeholder="Cerca prodotto..."></IonSearchbar>
                            <ProductList currentOrder={state.order} selectionEvent={selectProduct} deleteAction={(product: any) => deleteProduct(product)} categories={visibleCategories} />

                            {
                                ((searching && visibleCategories[0].products.length === 0) && <ImagePlaceholder text="Nessun risultato per la ricerca." image="search"></ImagePlaceholder>)}
                            {
                                (state.order.items.length > 0 && <div className="spacer"></div>)
                            }

                        </>
                        ) :
                        (<Skeleton></Skeleton>)
                }
            </IonContent>

            {(selectedProduct &&
                <IonModal cssClass="quantityModal"
                    isOpen={showSelectionModal}
                    swipeToClose={true}
                    onDidDismiss={() => setShowSelectionModal(false)}>
                    <QuantityModal item={selectedProduct} closeAction={() => { setShowSelectionModal(false) }} confirmAction={(item: any) => { addItemToOrder(item); setShowSelectionModal(false); }}></QuantityModal>
                </IonModal>)}

            {(selectedProduct &&
                <IonModal cssClass="componentsModal"
                    isOpen={showComponentsModal}
                    swipeToClose={true}
                    onDidDismiss={() => setShowComponentsModal(false)}>
                    <ComponibleModal item={selectedProduct} cancelAction={() => setShowComponentsModal(false)} confirmAction={(item: any) => { addItemToOrder(item); setShowComponentsModal(false); }}></ComponibleModal>
                </IonModal>
            )}

        </IonPage>
    );
};

export default NewOrderPage;
