import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonProgressBar, IonRow, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import ProductService from '../../../shared/services/ProductService';
import './ProductPage.css';
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from '../../../AppContextProvider';
import { formattedPrice } from '../../../helpers';
import ProductInfoEditor from '../../../components/Product/EditProduct/ProductInfoEditor/ProductInfoEditor';
import { trashBin } from 'ionicons/icons';
import { Modals } from '@capacitor/core';
import ProductImageEditor from '../../../components/Product/EditProduct/ProductImage/ProductImageManager';
import ProductComponentEditor from '../../../components/Product/EditProduct/ProductComponentsEditor/ProductComponentsEditor';
import { ComponentEditor } from '../../../components/Product/EditProduct/ProductComponentsEditor/ComponentEditor';
import { ComponentOptionEditor } from '../../../components/Product/EditProduct/ProductComponentsEditor/ComponentOptionEditor';
import Skeleton from '../../../components/Placeholders/Skeleton/Skeleton';

const ProductPage: React.FC = () => {

    const globalState = useContext(AppContext);
    const { state } = globalState;

    //const history = useHistory();
    const [productLoaded, setProductLoaded] = useState<boolean>(false);
    const [product, setProduct] = useState<Product>();
    const [currentEditingComponent, setCurrentEditingComponent] = useState<ProductComponent>();
    const [showComponentEditor, setShowComponentEditor] = useState<boolean>(false);
    const [currentEditingComponentOption, setCurrentEditingComponentOption] = useState<ProductComponentOption>();
    const [showComponentOptionEditor, setShowComponentOptionEditor] = useState<boolean>(false);

    const { id } = useParams<{ id: string; }>();
    const history = useHistory();

    const loadProduct = () => {
        setProductLoaded(false);
        ProductService.get(id).then((res: any) => {
            setProduct(res.data);
            setProductLoaded(true);
        });
    };

    const updateProduct = (product: any) => {
        ProductService.update(id, product).then((res) => {
            setProduct(res.data);
            loadProduct();
        });
    };

    const showDeleteDialog = () => {
        Modals.confirm({ title: "Elimina prodotto", message: "Sei sicuro di voler eliminare?", cancelButtonTitle: "Annulla", okButtonTitle: "Conferma" })
            .then((e) => {
                if (e.value) ProductService.destroy(id).then(() => { history.goBack(); });
            });
    }

    useEffect(() => {
        loadProduct();
    }, [id]);

    useEffect(() => {
    }, [product]);


    useEffect(() => {
        console.log('component:', currentEditingComponent);
    }, [currentEditingComponent]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Prodotto</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => showDeleteDialog()} color="danger"><IonIcon slot="start" icon={trashBin}></IonIcon>Elimina Prodotto</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {!productLoaded && (<IonProgressBar type="indeterminate"></IonProgressBar>)}
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Prodotto</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {product && productLoaded ? (
                    <>
                        <IonToolbar className="ion-padding-left">
                            <IonGrid>
                                <IonRow>
                                    <IonCol sizeLg="6">
                                        <IonItem>
                                            <IonLabel>Stato: </IonLabel>
                                            <IonSelect onIonChange={(e) => { updateProduct({ status: e.detail.value }) }} value={product.status}>
                                                <IonSelectOption value="active">Attivo</IonSelectOption>
                                                <IonSelectOption value="draft">Bozza</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>

                        </IonToolbar>
                        <IonGrid>
                            <IonRow>
                                <IonCol sizeLg="6">
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>Informazioni</IonCardTitle>
                                            <IonCardSubtitle>Qui puoi modificare le principali informazioni del prodotto. Fai clic su salva per salvare.</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <ProductInfoEditor product={product} categories={state.tenant.categories} confirmAction={(product: any) => updateProduct(product)}></ProductInfoEditor>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeLg="6">
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>Immagine</IonCardTitle>
                                            <IonCardSubtitle>Qui puoi gestire l'immagine mostrata nel catalogo per il prodotto.</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <ProductImageEditor product={product} onUpdate={(url: string) => setProduct({ ...product, cover_image_url: url })} />
                                        </IonCardContent>
                                    </IonCard>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>Personalizzazioni</IonCardTitle>
                                            <IonCardSubtitle>Puoi aggiungere componenti del prodotto che i tuoi clienti possono personalizzare.</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <ProductComponentEditor
                                                onDelete={loadProduct}
                                                showOptionEditorAction={(componentOption: ProductComponentOption, component: ProductComponent) => { setCurrentEditingComponent(component); setCurrentEditingComponentOption(componentOption); setShowComponentOptionEditor(true); }}
                                                showEditorAction={(component: ProductComponent) => { setCurrentEditingComponent(component); setShowComponentEditor(true); }}
                                                components={product.components} />
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                ) :
                    (<Skeleton></Skeleton>)
                }
            </IonContent>
            <IonModal isOpen={showComponentEditor} onDidDismiss={() => setShowComponentEditor(false)}>
                <ComponentEditor product_id={product?.id} component={currentEditingComponent} onComponentSaved={() => { loadProduct(); setShowComponentEditor(false); }} cancelAction={() => setShowComponentEditor(false)}></ComponentEditor>
            </IonModal>
            <IonModal isOpen={showComponentOptionEditor} onDidDismiss={() => setShowComponentOptionEditor(false)}>
                <ComponentOptionEditor component={currentEditingComponent} componentOption={currentEditingComponentOption} onComponentOptionSaved={() => { loadProduct(); setShowComponentOptionEditor(false); }} cancelAction={() => setShowComponentOptionEditor(false)}></ComponentOptionEditor>
            </IonModal>
        </IonPage >
    );
};

export default ProductPage;
