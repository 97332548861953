import React from 'react';
import { IonItem, IonText, IonNote } from '@ionic/react';
import './OrderDetailItem.css';
import { formattedPrice } from '../../../helpers';

interface ContainerProps {
    item: any
}

const OrderDetailItem: React.FC<ContainerProps> = ({ item }) => {


    return (
        <IonItem key={item.hash}>
            <IonText>
                <p>{item.product?.name}</p>
                <ul>
                    {item.component_strings.map((component: any) => <li key={component} className="item-options">{component}</li>)}
                </ul>
                <p className="item-price-text">Totale {formattedPrice(item.total)}€</p>
            </IonText>
            <IonNote slot="start">x{item.quantity}</IonNote>
        </IonItem>
    );
};

export default OrderDetailItem;
