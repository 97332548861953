import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonProgressBar, IonText, IonTextarea, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../../AppContextProvider';
import CartItem from '../../../components/Order/Cart/CartItem/CartItem';
import SelectMethodItem from '../../../components/Order/Cart/SelectMethodItem/SelectMethodItem';
import SelectSlotItem from '../../../components/Order/Cart/SelectSlotItem/SelectSlotItem';
import MessageModal from '../../../components/Modals/MessageModal/MessageModal';
import ImagePlaceholder from '../../../components/Placeholders/ImagePlaceholder/ImagePlaceholder';
import { formattedPrice, prepareOrder } from '../../../helpers';
import useInterval from '../../../shared/hooks/useInterval';
import OrderService from '../../../shared/services/OrderService';
import './CartPage.css';

const CartPage: React.FC = () => {
    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(state.tenant.available_payment_methods[0]);
    const [selectedMode, setSelectedMode] = useState(state.tenant.available_modes[0]);

    const [openModal, setOpenModal] = useState(false);
    const [modalProps, setModalProps] = useState<any | undefined>();
    const [slotsLoaded, setSlotsLoaded] = useState<boolean>(false);

    const history = useHistory();

    const loadNextSlots = () => {
        setSlotsLoaded(false);
        //console.log(state.order);
        OrderService.getNextSlots(selectedMode.key).then(data => {
            setAvailableSlots(data);
            updateSelectedSlot(data[0]);
            updateSelectedMethod(state.tenant.available_payment_methods[0].enum);
            setSlotsLoaded(true);
        });
    };

    const updateSelectedSlot = (slot: any) => {
        let order = state.order;
        order.datetime = slot;
        dispatch({ type: 'SET_ORDER', payload: order });
        setSelectedSlot(slot);
    };

    const updateSelectedMethod = (method: any) => {
        setSelectedMethod(method);
        let order = state.order;
        order.payment_method = method;
        dispatch({ type: 'SET_ORDER', payload: order });
    };

    const updateSelectedMode = (mode: any) => {
        setSelectedMode(mode);
        let order = state.order;
        order.mode = mode;
        dispatch({ type: 'SET_ORDER', payload: order });
    };

    const updateNotes = (notes: any) => {
        let order = state.order;
        order.notes = notes;
        dispatch({ type: 'SET_ORDER', payload: order });
    }

    const placeOrder = () => {
        const orderPayload = prepareOrder(state.order);
        OrderService.create(orderPayload).then(
            (data) => {
                const goBack = () => { setModalProps(undefined); dispatch({ type: 'REMOVE_ORDER' }); setTimeout(() => history.replace('/'), 200); };
                setModalProps({
                    cta: "Vai agli ordini",
                    text: "Ordine trasmesso correttamente!",
                    image: "confirm",
                    ctaAction: goBack,
                    show: true
                });
                setOpenModal(true);
            }
        ).catch(
            () => {
                const closeModal = () => { setModalProps({ ...{ show: false } }); };
                setModalProps({
                    cta: "Chiudi",
                    text: "L'ordine non è stato completato",
                    image: "error",
                    ctaAction: closeModal,
                    show: true
                });
                setOpenModal(true);
            });
    };

    useIonViewDidEnter(() => {
        updateSelectedMode(state.tenant.available_modes[0]);
        updateSelectedMethod(state.tenant.available_payment_methods[0]);
        loadNextSlots();
    }, []);

    useEffect(() => {
        console.log('refreshed datetime:', state.order.datetime);
    }, [state.order, state.tenant]);

    // useEffect(() => {
    //     updateSelectedSlot(state.order.datetime);
    // }, [state.order.datetime]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons>
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        Riepilogo
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {!slotsLoaded && (<IonProgressBar type="indeterminate"></IonProgressBar>)}
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Riepilogo</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonList>{
                    state.order.datetime && (
                        <>
                            <IonItemDivider>Orario di {(state.order.mode === 'delivery') ? 'consegna' : 'ritiro'}</IonItemDivider>
                            <SelectSlotItem currentSlot={state.order.datetime} slots={availableSlots} selectionAction={(slot: any) => updateSelectedSlot(slot)}></SelectSlotItem>
                        </>
                    )}{/*<IonItemDivider>Metodo di pagamento</IonItemDivider> 
                     <SelectMethodItem methods={state.tenant.available_payment_methods} selectionAction={(method: any) => { console.log('method enum: ', method); updateSelectedMethod(method); }}></SelectMethodItem> */}
                    {(state.order.items.length > 0 &&
                        <IonItem>
                            <IonLabel>Aggiungi note:</IonLabel>
                            <IonTextarea onIonChange={(e: any) => updateNotes(e.detail.value)}></IonTextarea>
                        </IonItem>
                    )}
                    <IonItemDivider>Prodotti del carrello</IonItemDivider>
                    {(state.order.items.length < 1 &&
                        <ImagePlaceholder text="Non ci sono prodotti nel carrello." image="list"></ImagePlaceholder>
                    )}
                    {state.order.items.map((item: OrderItem) =>
                        <CartItem key={item.hash} item={item}></CartItem>)}
                    {(state.order.total > 0 &&
                        <IonItem className="ion-margin-top">
                            <IonTitle className="total-text" size="large">Totale</IonTitle>
                            <IonText slot="end">{formattedPrice(state.order.total)} €</IonText>
                        </IonItem>)}
                </IonList>
                {((state.order.items.length > 0 && state.token && state.order.datetime) &&
                    <IonButton onClick={() => placeOrder()} expand="block">Concludi Ordine</IonButton>)}
                {(openModal && <MessageModal modalProps={modalProps}></MessageModal>)}
            </IonContent>
        </IonPage>
    );
};

export default CartPage;
