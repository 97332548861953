const Printer = (function () {
    var _service;
    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }
    function _printOrder(title) {
        let mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + title + '</title>');
        mywindow.document.write("<style>table {font-family: arial, sans-serif;border-collapse: collapse;width: 100%;} td, th {  border: 1px solid #dddddd;  text-align: left;  padding: 8px;} tr:nth-child(even) {  background-color: #dddddd;}</style>");
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1>' + title + '</h1>');
        mywindow.document.write('</body></html>');
        //mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write(document.getElementById('printable').innerHTML);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }
    return {
        printOrder: _printOrder
    };
})();


export default Printer;