import React from 'react';
import { IonItem, IonLabel, IonText, IonCheckbox, IonNote } from '@ionic/react';
import './OptionItem.css';

interface ContainerProps {
  option: ProductComponentOption,
  selectionEvent: Function
}

const OptionItem: React.FC<ContainerProps> = ({ option, selectionEvent }) => {

  return (
    <IonItem>
      <IonLabel>
        <IonText>{option.name}</IonText>
        <p>{option.description}</p>
      </IonLabel>
      {option.price.amount > 0 && <IonNote slot="end">+ {option.price.formatted}</IonNote>}
      <IonCheckbox onIonChange={(e)=>selectionEvent(e.detail.checked, option)} slot="end" checked={option.default} />
    </IonItem>
  );
};

export default OptionItem;
