import { Modals } from '@capacitor/core';
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonNote } from '@ionic/react';
import { pencil, trash } from 'ionicons/icons';
import React from 'react';
import ProductComponentOptionService from '../../../../shared/services/ProductComponentOptionService';

interface ItemProps {
    option: ProductComponentOption,
    editComponentOptionAction: Function,
    onDelete: Function
}

export const ComponentOptionItem: React.FC<ItemProps> = ({ option, editComponentOptionAction, onDelete }) => {

    const deletePrompt = () => {
        const prompt = Modals.confirm({ title: "Conferma eliminazione", message: "Sei sicuro di voler eliminare l'opzione? Non potrai più recuperarla", okButtonTitle: "Elimina", cancelButtonTitle: "Annulla" })
        .then((e) => { if (e.value) deleteComponentOption(option.id as string);});
    };

    const deleteComponentOption = (id: string | number) => {
        ProductComponentOptionService.destroy(id).then(() => {
            onDelete();
        });
    }
    return (
        <IonItem key={option.id}>
            <IonButtons slot="start">
                <IonButton onClick={() => editComponentOptionAction(option)} fill="clear" slot="start"><IonIcon icon={pencil} /></IonButton>
                <IonButton onClick={deletePrompt} fill="clear" color="danger" slot="end"><IonIcon icon={trash} /></IonButton>
            </IonButtons>
            <IonLabel>{option.name}</IonLabel>
            {(option.price.amount > 0 && <IonNote slot="end">{option.price.formatted}</IonNote>)}
            <p>{(option.default) ? 'Default' : ''}</p>
        </IonItem>
    );
};