import React, { useRef } from 'react';
import { IonItem, IonLabel, IonText, IonThumbnail, IonImg, IonItemSliding, IonItemOptions, IonItemOption } from '@ionic/react';
import './ProductListItem.css';
import { countOrderProduct, formattedPrice, orderHashes } from '../../../../../helpers';

interface ContainerProps {
  product: any,
  selectionEvent: Function,
  deleteAction: Function,
  currentOrder: any
}

const ProductListItem: React.FC<ContainerProps> = ({ currentOrder, product, selectionEvent, deleteAction }) => {

  const parent = useRef<any>(null);

  return (
    <IonItemSliding ref={parent}>
      <IonItemOptions side="end">
        {countOrderProduct(currentOrder, product) > 0 && (
          <IonItemOption color="danger" onClick={() => { parent.current.close(); deleteAction(product); }}>Elimina</IonItemOption>)}
      </IonItemOptions>
      <IonItem className={(countOrderProduct(currentOrder, product) ? 'selected-product' : '')} onClick={(e) => { selectionEvent(product); }}>
        <IonLabel className="ion-text-wrap">
          <IonText>
            <h2>{countOrderProduct(currentOrder, product) > 0 && (
              <span className="item-count-text">x{countOrderProduct(currentOrder, product)}</span>
            )} {product.name}</h2>
          </IonText>
          <p>{product.description}</p>
          <IonText className="ion-margin-top" color="primary">
            <h3>{product.price.amount < product.full_price.amount && (
              <span className="fullprice">{product.full_price.formatted}</span>)} {product.price.formatted}</h3>
          </IonText>
          {product.componible && (
            <IonText color="warning">
              <h4 className="componible">Componibile</h4>
            </IonText>
          )}
        </IonLabel>

        {product.cover_image_url && (
          <IonThumbnail slot="end">
            <IonImg src={product.cover_image_url} />
          </IonThumbnail>
        )}
      </IonItem>
    </IonItemSliding>
  );
};

export default ProductListItem;
