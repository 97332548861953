import React from 'react';
import { IonItem, IonLabel, IonText, IonNote, IonRadio } from '@ionic/react';

interface ContainerProps {
    option: ProductComponentOption,
    // selectionEvent: Function
}

const RadioItem: React.FC<ContainerProps> = ({ option }) => {
    return (
        <IonItem onClick={() => { }}>
            <IonLabel>
                <IonText>{option.name}</IonText>
                <p>{option.description}</p>
            </IonLabel>
            {option.price.amount > 0 && <IonNote slot="end">+ {option.price.formatted}</IonNote>}
            <IonRadio slot="end" value={option.id} />

        </IonItem>
    );
};

export default RadioItem;
