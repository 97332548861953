import request from '../request'


function getMenu(mode: string = '') {
    return request({
        url: `menu/mode/${mode}`,
        method: 'GET'
    });
}


const MenuService = { getMenu };
export default MenuService;