import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonNote, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { Component, useEffect, useState } from 'react';
import ProductComponentService from '../../../../shared/services/ProductComponentService';
import ProductComponentEditor from './ProductComponentsEditor';

interface ItemProps {
    product_id?: string | number
    component?: ProductComponent,
    onComponentSaved: Function,
    cancelAction: Function
}

export const ComponentEditor: React.FC<ItemProps> = ({ product_id, component, onComponentSaved, cancelAction }) => {


    const newComponent = (): ProductComponent => {
        const emptyComponent: ProductComponent =
        {
            name: "",
            description: "",
            required: false,
            multiple: false,
            product_id: product_id
        };

        return emptyComponent;
    }
    console.log('component on editor:', component);
    const [componentObject, setComponentObject] = useState<ProductComponent>(component ?? newComponent());
    const [validForm, setValidForm] = useState<boolean>(false);

    const updateComponent = (field: string, value: string | number | undefined | null) => {
        let prod: any = componentObject; prod[field] = value;
        checkFields();
        setComponentObject(prod);

    }
    const checkFields = () => {
        let valid = true;
        //if () valid = false;
        setValidForm(valid);
        return valid;
    }
    const submit = () => {
        if (checkFields()) {
            //confirmAction(componentObject);
        }
        if (componentObject.id) {
            ProductComponentService.update(componentObject.id, componentObject).then((res) => { console.log(res); onComponentSaved(res); });
        }
        else {
            ProductComponentService.create(componentObject).then((res) => { console.log(res); onComponentSaved(res); });
        }
    }

    useEffect(() => {

    }, []);


    return (
        <>
            <IonContent>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Nuovo componente</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => cancelAction()}>Annulla</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonItem>
                    <IonLabel position="stacked">Nome</IonLabel>
                    <IonInput value={componentObject.name} onIonChange={(e) => updateComponent('name', e.detail.value)} type="text"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Descrizione</IonLabel>
                    <IonInput value={componentObject.description} onIonChange={(e) => updateComponent('description', e.detail.value)} type="text"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Obbligatorio</IonLabel>
                    <IonSelect value={componentObject.required} onIonChange={(e) => updateComponent('required', e.detail.value)}>
                        <IonSelectOption value={false}>No</IonSelectOption>
                        <IonSelectOption value={true}>Sì</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">A scelta multipla</IonLabel>
                    <IonSelect value={componentObject.multiple} onIonChange={(e) => updateComponent('multiple', e.detail.value)}>
                        <IonSelectOption value={false}>No</IonSelectOption>
                        <IonSelectOption value={true}>Sì</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonButton disabled={!validForm} className="ion-margin-bottom" expand="block" onClick={() => { submit(); }}>Salva componente</IonButton>
            </IonFooter>
        </>
    );
};