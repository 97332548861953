import React, { useContext, useEffect, useRef } from 'react';
import { IonButton, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonNote, IonIcon, IonToggle, IonList, IonImg, IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { useState } from 'react';
import { add, arrowUpCircleSharp, checkmarkCircle, documentAttach, informationCircleOutline, refreshCircle, trashBin } from 'ionicons/icons';
import ImagePlaceholder from '../../../Placeholders/ImagePlaceholder/ImagePlaceholder';
import ProductService from '../../../../shared/services/ProductService';
import { Modals, Toast } from '@capacitor/core';


interface ContainerProps {
    product: Product,
    onUpdate: Function
}

const ProductImageEditor: React.FC<ContainerProps> = ({ product, onUpdate }) => {

    const [validForm, setValidForm] = useState<boolean>(false);
    const [showDiscount, setShowDiscount] = useState<boolean>(product.full_price.amount > 0);

    const [imageSrc, setImgSrc] = useState<string | null>(product.cover_image_url ?? null);
    useEffect(() => {

    }, []);

    const imageRef = useRef<any>();
    const [selectedFile, setSelectedFile] = useState<any>();
    // On click on camera icon open the dialog
    const showOpenFileDialog = () => {
        console.log('click');
        imageRef.current.click();
    };
    // On each change let user have access to a selected file
    const handleChange = (event: any) => {
        console.log('change');
        const file = event.target.files[0];
        setSelectedFile(file);
    };
    // Clean up the selection to avoid memory leak
    useEffect(() => {
        if (selectedFile) {
            const objectURL = URL.createObjectURL(selectedFile);
            setImgSrc(objectURL);
            return () => URL.revokeObjectURL(objectURL);
        }
    }, [selectedFile]);

    const updateImage = () => {
        ProductService.updateImage(product.id, selectedFile).then((res: any) => {
            console.log('upload complete');
            setImgSrc(res.url);
            Toast.show({ text: "Immagine caricata.", position: "bottom", duration: "short" })
            onUpdate(res.url);
        });
    }


    const deleteImage = () => {
        ProductService.destroyImage(product.id).then(() => {
            setImgSrc(null);
            Toast.show({ text: "Immagine eliminata.", position: "bottom", duration: "short" });
            onUpdate(null);
        });
    }

    const deletePrompt = () => {
        const prompt = Modals.confirm({ title: "Conferma eliminazione", message: "Sei sicuro di voler eliminare l'immagine? Non potrai più recuperarla", okButtonTitle: "Elimina", cancelButtonTitle: "Annulla" })
            .then((e) => { if (e.value) deleteImage();});
    }

    useEffect(() => {
    }, [product]);


    return (

        <IonGrid>
            <input
                ref={imageRef}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleChange} />
            <IonRow>
                <IonCol>
                    {
                        (imageSrc) ? <IonImg className="image" src={imageSrc} />
                            :
                            <ImagePlaceholder text="" image="pic"></ImagePlaceholder>
                    }
                </IonCol>
            </IonRow>
            <IonRow className="ion-text-center ion-justify-content-center">
                {(product.cover_image_url === imageSrc && imageSrc) &&
                    <IonButtons>
                        <IonButton onClick={() => showOpenFileDialog()} color="primary">Sostituisci <IonIcon slot="end" icon={documentAttach}></IonIcon></IonButton>
                        <IonButton onClick={() => deletePrompt()} color="danger">Rimuovi <IonIcon slot="end" icon={trashBin}></IonIcon></IonButton>
                    </IonButtons>}
                {(product.cover_image_url !== imageSrc && imageSrc) &&
                    <IonButtons>
                        <IonButton onClick={() => updateImage()} color="success">Salva <IonIcon slot="end" icon={checkmarkCircle}></IonIcon></IonButton>
                        <IonButton onClick={() => setImgSrc(product.cover_image_url ?? null)} color="danger">Annulla <IonIcon slot="end" icon={trashBin}></IonIcon></IonButton>
                    </IonButtons>}
                {(!imageSrc) &&
                    <IonButton onClick={() => showOpenFileDialog()}>Aggiungi immagine <IonIcon slot="end" icon={add}></IonIcon></IonButton>
                }
            </IonRow>
        </IonGrid >

    );
};

export default ProductImageEditor;