import request from '../request'

function list() {
    return request({
        url: `admin/category`,
        method: 'GET'
    });
}

function create(category: any) {
    return request({
        url: `admin/category`,
        method: 'POST',
        data: category
    });
}

function update(id: number | string, params: any) {
    return request({
        url: `admin/category/${id}`,
        method: 'PUT',
        data: params
    });
}

function updateSortOrder(order_ids: any) {
    return request({
        url: `admin/category-order`,
        method: 'PUT',
        data: { ordered_ids: order_ids }
    });
}

function destroy(id: number | string) {
    return request({
        url: `admin/category/${id}`,
        method: 'DELETE'
    });
}



const CategoryService = { list, create, update, updateSortOrder, destroy };
export default CategoryService;