import Menu from './components/Menu/Menu';
import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/**
 * Pages
 */
import OrdersPage from './pages/Orders/OrdersPage/OrdersPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/** Context */
import { AppContext, AppContextProvider } from './AppContextProvider';
import LoginPage from './pages/Login/LoginPage/LoginPage';
import OrderDetailPage from './pages/Orders/OrderDetailPage/OrderDetailPage';
import NewOrderPage from './pages/Orders/NewOrderPage/NewOrderPage';
import CartPage from './pages/Orders/CartPage/CartPage';
import ProductsPage from './pages/Catalog/ProductsPage/ProductsPage';
import ProductPage from './pages/Catalog/ProductPage/ProductPage';
import CategoriesPage from './pages/Catalog/CategoriesPage/CategoriesPage';

const App: React.FC = () => {

  return (
    <IonApp>
      <AppContextProvider>
        <IonReactRouter>
          <Route path="/login" component={LoginPage} />
          <AppContext.Consumer>
            {state => (!state.state.token) ?
              <>
                <Redirect to="/login" />
              </>
              :
              <IonSplitPane contentId="main">
                <Menu />
                <IonRouterOutlet id="main">
                  <Route path="/order" component={NewOrderPage} exact />
                  <Route path="/order/checkout/cart" component={CartPage} exact />
                  <Route path="/order/:id" component={OrderDetailPage} exact />
                  <Route path="/orders" component={OrdersPage} exact />
                  <Route path="/catalog" component={ProductsPage} exact />
                  <Route path="/categories" component={CategoriesPage} exact />
                  <Route path="/product/:id" component={ProductPage} exact />

                  <Redirect from="/login" to="/orders" exact />
                  <Redirect from="/" to="/orders" exact />
                </IonRouterOutlet>
              </IonSplitPane>
            }
          </AppContext.Consumer>
        </IonReactRouter>
      </AppContextProvider>
    </IonApp>
  );
};

export default App;
