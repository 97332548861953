import React, { useContext, useEffect } from 'react';
import { IonLabel, IonList, IonItemGroup, IonItemDivider } from '@ionic/react';
// import './ExploreContainer.css';
import ProductListItem from '../Item/ProductListItem';


interface ContainerProps {
    categories: Category[],
    selectionEvent: Function,
    deleteAction: Function,
    currentOrder: any
}

const ProductList: React.FC<ContainerProps> = ({ categories, selectionEvent, deleteAction, currentOrder }) => {
    console.log('order from list: ', currentOrder);

    return (
        <IonList>
            {categories.map(category => (
                <IonItemGroup key={category.id}>
                    <IonItemDivider>
                        <IonLabel>
                            {category.name}
                        </IonLabel>
                    </IonItemDivider>
                    {category.products.map(product => (
                        <ProductListItem key={product.id} currentOrder={currentOrder} selectionEvent={selectionEvent} deleteAction={(product:any)=>deleteAction(product)} product={product} />
                    ))}
                </IonItemGroup>))}
        </IonList>
    );
};

export default ProductList;
