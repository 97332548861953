import { Modals } from '@capacitor/core';
import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInfiniteScroll, IonInfiniteScrollContent, IonMenuButton, IonPage, IonProgressBar, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { OrderList } from '../../../components/Order/OrderList/OrderList';
import OrderPageToolbar from '../../../components/Order/OrderPageToolbar/OrderPageToolbar';
import useInterval from '../../../shared/hooks/useInterval';
import OrderService from '../../../shared/services/OrderService';
import './OrdersPage.css';
import { useHistory } from "react-router-dom";
import ImagePlaceholder from '../../../components/Placeholders/ImagePlaceholder/ImagePlaceholder';
import Skeleton from '../../../components/Placeholders/Skeleton/Skeleton';

const OrdersPage: React.FC = () => {

    const history = useHistory();
    const [orders, setOrders] = useState<Order[]>([]);
    const [ordersLoaded, setOrdersLoaded] = useState<boolean>(false);
    const [firstLoadComplete, setFirstLoadComplete] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useState({});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>();
    const [infiniteLoading, setInfiniteLoading] = useState<boolean>(false);

    const loadOrders = () => {
        setOrdersLoaded(false);
        OrderService.list(searchParams).then((data) => {
            setOrders((infiniteLoading) ? orders.concat(data.data) : data.data);
            setLastPage(data.last_page);
            setCurrentPage(data.current_page);
            setOrdersLoaded(true);
            setInfiniteLoading(false);
            setFirstLoadComplete(true);
        });
    };

    const verifyScannedOrder = (uid: string) => {
        OrderService.list({ uid: uid }).then((data) => {
            if (data.count > 0) {
                const orderId = data.data[0].id;
                const orderPath = `/order/${orderId}`;
                history.push(orderPath);
            }
            else {
                Modals.alert({ title: "CODICE NON VALIDO", message: "Il codice QR non è valido" });
            }
        });
    }

    const goToNewOrderPage = () => {
        history.push('/order');
    }

    // useEffect(() => {
    //     if (!searchParams.hasOwnProperty('page')) { setSearchParams(Object({ page: 1, per_page: 25 })); }
    //     loadOrders();
    // }, [searchParams]);

    useInterval(() => {
        setSearchParams(Object({ page: 1, per_page: 25 }));
        loadOrders();
    }, 60000);

    useIonViewWillEnter(() => {
        setSearchParams(Object({ page: 1, per_page: 25 }));
        loadOrders();
    });


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Ordini</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {/* {(!ordersLoaded && firstLoadComplete) && (<IonProgressBar type="indeterminate"></IonProgressBar>)} */}
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Ordini</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <OrderPageToolbar onCompleteScan={(uid: string) => verifyScannedOrder(uid)} onCreateButtonClick={() => goToNewOrderPage()} onFilterButtonClick={() => { }} ></OrderPageToolbar>
                {ordersLoaded ? (
                    orders.length > 0 ?
                        (
                            <OrderList orders={orders}></OrderList>) :
                        <IonGrid>
                            <IonCol><ImagePlaceholder size="6" image="nodata" text="Nessun ordine in arrivo per oggi." /></IonCol>
                        </IonGrid>)
                    :
                    !firstLoadComplete && (<Skeleton></Skeleton>)
                }
                <IonInfiniteScroll disabled={(currentPage === lastPage)} threshold="100px" onIonInfinite={(e: any) => { setInfiniteLoading(true); setSearchParams({ ...searchParams, page: currentPage + 1 }); setTimeout(() => { e.target.complete() }, 3000); }}>
                    <IonInfiniteScrollContent loadingSpinner="circular" loadingText="Carico altri..."></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>

        </IonPage>
    );
};

export default OrdersPage;
