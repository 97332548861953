import React, { useState } from 'react';
import { IonList, IonItemGroup, IonListHeader } from '@ionic/react';
import './ProductOptionsSelector.css';
import RadioGroup from './RadioGroup';
import OptionGroup from './OptionGroup';
import { options } from 'ionicons/icons';


interface ContainerProps {
    components: any,
    updateCart: Function
}

const ProductOptionSelector: React.FC<ContainerProps> = ({ components, updateCart }) => {

    const initializeComponentOptions = () => {
        return components.map((c: any) => { return { id: c.id, name: c.name, selectedOptions: [] }; });
    };

    const [optionsPerComponent, setOptionsPerComponent] = useState(initializeComponentOptions());

    const updateOptions = (component: ProductComponent, options: ProductComponentOption[]) => {
        let opts = optionsPerComponent;
        opts.filter((opt: any) => opt.id === component.id)[0].selectedOptions = options;
        setOptionsPerComponent(opts);
        updateCart(optionsPerComponent);
    };

    return (
        <>
            <IonList className="components_list">
                {components.map((component: ProductComponent) =>
                    <IonItemGroup key={component.id}>
                        <IonListHeader>
                            {component.name}
                        </IonListHeader>
                        {
                            !component.multiple &&
                            <RadioGroup selectionAction={(options: ProductComponentOption[]) => { updateOptions(component, options) }} component={component}></RadioGroup>
                        }
                        {
                            component.multiple &&
                            <OptionGroup selectionAction={(options: ProductComponentOption[]) => { updateOptions(component, options) }} component={component}></OptionGroup>
                        }
                    </IonItemGroup>)}
            </IonList>
        </>
    );
};

export default ProductOptionSelector;
