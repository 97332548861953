import { IonButton, IonButtons, IonIcon, IonItem, IonList, IonReorderGroup } from '@ionic/react';
import { pencil } from 'ionicons/icons';
import React from 'react';
import { CategoryItem } from '../CategoryItem/CategoryItem';

interface ItemProps {
    categories: Category[],
    deleteAction: Function,
    editAction: Function,
    onReorder: Function
}

export const CategoryList: React.FC<ItemProps> = ({ categories, deleteAction, editAction, onReorder }) => {

    return (
        <>
            <IonList>
                <IonReorderGroup onIonItemReorder={(e)=>onReorder(e)} disabled={false}>
                    {categories.map((category: Category) =>
                        <CategoryItem key={category.id}
                            category={category}
                            deleteAction={(category: Category) => deleteAction(category)}
                            editAction={(category: Category) => editAction(category)} />)}
                </IonReorderGroup>
            </IonList>
        </>

    );
}