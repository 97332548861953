import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonModal, IonButton, IonIcon, useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { AppContext } from '../../../AppContextProvider';
import CategoryService from '../../../shared/services/CategoryService';
import { CategoryList } from '../../../components/Catalog/CategoryList/CategoryList';
import CategoryEditor from '../../../components/Catalog/CategoryEditor/CategoryEditor';
import { addCircle, car } from 'ionicons/icons';
import { Modals } from '@capacitor/core';

const CategoriesPage: React.FC = () => {

    const globalState = useContext(AppContext);
    const { state } = globalState;
    const { dispatch } = globalState;

    const [categories, setCategories] = useState<any>(state.tenant.categories);
    const [categoriesLoaded, setCategoriesLoaded] = useState<boolean>(true);
    const [showNewCategoryModal, setShowNewCategoryModal] = useState<boolean>(false);
    const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [] = useState<boolean>(false);

    const loadCategories = () => {
        setCategoriesLoaded(false);
        CategoryService.list().then((data: any) => {
            setCategories(data);
            setCategoriesLoaded(true);
            dispatch({ 'type': 'SET_TENANT', 'payload': { ...state.tenant, categories: data } });
        });
    };

    const createCategory = (newCategory: any) => {
        CategoryService.create(newCategory).then(() => {
            setShowNewCategoryModal(false);
            loadCategories();
        });
    }

    const updateCategory = (category: any) => {
        CategoryService.update(category.id, category).then(() => {
            setShowUpdateCategoryModal(false);
            loadCategories();
        });
    }

    const showDeleteDialog = (category_id: string | number) => {
        Modals.confirm({ title: "Elimina categoria", message: "Sei sicuro di voler eliminare la categoria? I prodotti associati non verranno più visualizzati nel menù", cancelButtonTitle: "Annulla", okButtonTitle: "Conferma" })
            .then((e) => {
                if (e.value) CategoryService.destroy(category_id).then(() => loadCategories());
            });
    }
    const doReorder = (event: CustomEvent) => {

        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. Update the items variable to the
        // new order of items
        //this.items = event.detail.complete(this.items);
        // const old_order_ids = categories.map((cat: any) => cat.id);
        // console.log('old order:', old_order_ids);
        const itemMove = categories.splice(event.detail.from, 1)[0];
        const new_order = categories.splice(event.detail.to, 0, itemMove);
        console.log('new:', categories.map((cat: any) => cat.id));
        updateCategoriesOrder(categories.map((cat: any) => cat.id));
        // After complete is called the items will be in the new order
        event.detail.complete();


    }

    const updateCategoriesOrder = (order_ids: string | number[]) => {
        CategoryService.updateSortOrder(order_ids).then((data: any) => {
            console.log('updated');
        });
    }

    useEffect(() => {
        //console.log('effect applied');
    }, [categories]);


    useIonViewDidEnter(() => loadCategories(), []);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Categorie</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowNewCategoryModal(true)}><IonIcon slot="start" icon={addCircle}></IonIcon> Nuova categoria</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Categorie</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <CategoryList onReorder={(e: any) => doReorder(e)} deleteAction={(category: Category) => showDeleteDialog(category.id)} editAction={(category: Category) => { setShowUpdateCategoryModal(true); setSelectedCategory(category); }} categories={categories}></CategoryList>
            </IonContent>

            <IonModal onDidDismiss={() => setShowNewCategoryModal(false)} isOpen={showNewCategoryModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Nuova Categoria</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowNewCategoryModal(false)}>Annulla</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <CategoryEditor confirmAction={(newCategory: any) => createCategory(newCategory)} />
                </IonContent>
            </IonModal>
            <IonModal onDidDismiss={() => setShowUpdateCategoryModal(false)} isOpen={showUpdateCategoryModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Modifica Categoria</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowUpdateCategoryModal(false)}>Annulla</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <CategoryEditor category={selectedCategory} confirmAction={(category: any) => updateCategory(category)} />
                </IonContent>
            </IonModal>

        </IonPage >
    );
};

export default CategoriesPage;
