import React, { useEffect, useState } from 'react';
import { IonItem, IonText, IonNote, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import Moment from 'react-moment';

interface ContainerProps {
    slots: any,
    selectionAction: Function,
    currentSlot?: string | null
}

const SelectSlotItem: React.FC<ContainerProps> = ({ slots, selectionAction, currentSlot }) => {


    const [selectedSlot, setSelectedSlot] = useState<any>((currentSlot) ?? (slots[0]) ? slots[0] : null);

    const selectionTrigger = (slot: any) => {
        selectionAction(slot);
        setSelectedSlot(slot);
    };

    // useEffect(() => {

    //     console.log('current slot on rendering: ', currentSlot);

    //     switch (currentSlot) {
    //         case null:
    //             setSelectedSlot((slots[0]) ?? null);
    //             break;

    //         default:
    //             setSelectedSlot(currentSlot);
    //             break;
    //     }
    // }, []);

    // useEffect(() => {

    // }, [currentSlot]);


    return (
        <IonItem>
            <IonLabel>Scegli un orario:</IonLabel>
            <IonSelect value={selectedSlot} placeholder="Seleziona" onIonChange={e => selectionTrigger(e.detail.value)}>
                {slots.map((slot: any) => <IonSelectOption key={slot} value={slot}>{<Moment format="HH:mm" date={slot}></Moment>}</IonSelectOption>)}
            </IonSelect>
        </IonItem>
    );
};

export default SelectSlotItem;
