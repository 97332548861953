import React, { useContext, useEffect } from 'react';
import { IonButton, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonNote, IonIcon, IonToggle } from '@ionic/react';
import { useState } from 'react';
import { AppContext } from '../../../AppContextProvider';
import { informationCircleOutline } from 'ionicons/icons';


interface ContainerProps {
    confirmAction: Function,
    cancelAction: Function
}

const NewProductModal: React.FC<ContainerProps> = ({ confirmAction, cancelAction }) => {

    const [newProduct, setNewProduct] = useState<any>({ componible: false });
    const [validForm, setValidForm] = useState<boolean>(false);
    const [showDiscount, setShowDiscount] = useState<boolean>(false);
    const globalState = useContext(AppContext);
    const { state } = globalState;


    const updateNewProduct = (field: string, value: string | number | undefined | null) => {
        let prod: any = newProduct; prod[field] = value;
        setNewProduct(prod);
        checkFields();
    }
    const checkFields = () => {
        let valid = true;
        if (!newProduct.name || !newProduct.description || !newProduct.category_id || !newProduct.price) valid = false;
        setValidForm(valid);
        return valid;
    }
    const submit = () => {
        if (checkFields()) {
            confirmAction(newProduct);
        }
    }

    useEffect(() => {

    }, []);


    return (
        <>
            <IonContent>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Nuovo prodotto</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => cancelAction()}>Annulla</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonItem>
                    <IonLabel position="stacked">Nome</IonLabel>
                    <IonInput onIonChange={(e) => updateNewProduct('name', e.detail.value)} type="text"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Descrizione</IonLabel>
                    <IonInput onIonChange={(e) => updateNewProduct('description', e.detail.value)} type="text"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Categoria</IonLabel>
                    <IonSelect onIonChange={(e) => updateNewProduct('category_id', e.detail.value)}>
                        {(state.tenant.categories.map((category: any) =>
                            <IonSelectOption key={category.id} value={category.id}>{category.name}</IonSelectOption>)
                        )}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Componibile</IonLabel>
                    <IonSelect value={false} onIonChange={(e) => updateNewProduct('componible', e.detail.value)}>
                        <IonSelectOption value={false}>No</IonSelectOption>
                        <IonSelectOption value={true}>Sì</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Disponibile per</IonLabel>
                    <IonSelect multiple onIonChange={(e) => updateNewProduct('available_modes', e.detail.value)}>
                        {state.tenant.available_modes.map((mode: any) =>
                            <IonSelectOption key={mode.enum} value={mode.enum}>{mode.name}</IonSelectOption>
                        )}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Prezzo finale (€)</IonLabel>
                    <IonInput onIonChange={(e) => { updateNewProduct('price', e.detail.value); }} type="number" min="0"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Mostra uno sconto</IonLabel>
                    <IonToggle onIonChange={(e) => { setShowDiscount(e.detail.checked); console.log(e.detail.checked) }}></IonToggle>
                </IonItem>
                <IonItem hidden={!showDiscount}>
                    <IonLabel position="stacked">Prezzo barrato (€)</IonLabel>
                    <IonInput onIonChange={(e) => updateNewProduct('full_price', e.detail.value)} type="number" min="0"></IonInput>
                    <IonNote onClick={() => { alert('Questo valore verrà mostrato come scontato.'); }} slot="end"><IonIcon icon={informationCircleOutline} /></IonNote>
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonButton disabled={!validForm} className="ion-margin-bottom" expand="block" onClick={() => { submit(); }}>Crea prodotto</IonButton>
            </IonFooter>
        </>
    );
};

export default NewProductModal;