import request from '../request'

function create(product: any) {
    return request({
        url: `admin/product-component-option`,
        method: 'POST',
        data: product
    });
}

function update(id: number | string, params: any) {
    return request({
        url: `admin/product-component-option/${id}`,
        method: 'PUT',
        data: params
    });
}

function destroy(id: number | string) {
    return request({
        url: `admin/product-component-option/${id}`,
        method: 'DELETE'
    });
}



const ProductComponentOptionService = { create, update, destroy };
export default ProductComponentOptionService;