import request from '../request'


function list(params: any = null) {
    return request({
        url: `admin/product`,
        method: 'GET',
        params: params
    });
}

function get(id: string | number) {
    return request({
        url: `admin/product/${id}`,
        method: 'GET'
    });
}

function create(product: any) {
    return request({
        url: `admin/product`,
        method: 'POST',
        data: product
    });
}

function update(id: number | string, params: any) {
    return request({
        url: `admin/product/${id}`,
        method: 'PUT',
        data: params
    });
}

function destroy(id: number | string) {
    return request({
        url: `admin/product/${id}`,
        method: 'DELETE'
    });
}

function createImage(product_id: string | number, image: any) {

    let formData = new FormData();
    formData.append('image', image);

    return request({
        url: `admin/product/${product_id}/image`,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: 'POST',
        data: formData
    });
}

function updateImage(product_id: string | number, image: any) {


    let formData = new FormData();
    formData.append('image', image);
    formData.append('_method', 'PUT');

    return request({
        url: `admin/product/${product_id}/image`,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: 'POST',
        data: formData
    });
}

function destroyImage(product_id: string | number) {
    return request({
        url: `admin/product/${product_id}/image`,
        method: 'DELETE'
    });
}


const ProductService = { get, list, create, update, destroy, createImage, updateImage, destroyImage };
export default ProductService;