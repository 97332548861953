import { options } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import OptionItem from './OptionItem';

interface ContainerProps {
    component: ProductComponent,
    selectionAction: Function
}

const OptionItemGroup: React.FC<ContainerProps> = ({ component, selectionAction }) => {

    const [selectedOptions, setSelectedOptions] = useState<ProductComponentOption[]>([]);

    const selectionEvent = (status: boolean, option: ProductComponentOption) => {
        let updatedOptions: ProductComponentOption[] = selectedOptions;
        switch (status) {
            case true:
                updatedOptions.push(option);
                break;

            case false:
                const itemPos = updatedOptions.map(i => i.id).indexOf(option.id);
                if (itemPos !== -1) updatedOptions.splice(itemPos);
                break;
        }
        setSelectedOptions(updatedOptions);
        selectionAction(updatedOptions);
    };

    const initCount = () => {
        component.options?.forEach(option => { if (option.default) selectionEvent(true, option); });
    };

    useEffect(() => {
        initCount();
    }, []);


    return (
        <>
            { component.options?.map(option =>
                <OptionItem selectionEvent={(status: boolean, option: ProductComponentOption) => selectionEvent(status, option)} key={option.id} option={option} />
            )}
        </>
    );
};

export default OptionItemGroup;
