
import React, { createContext, useReducer, useState, useEffect, useContext } from "react";
import { Plugins } from '@capacitor/core';
import UserService from "./shared/services/UserService";
import InitService from "./shared/services/InitService";
const { Storage } = Plugins;


const initialOrder = (() => {
    return {
        total: 0,
        items: [],
        mode: null,
        payment_method: null,
        datetime: null,
        uid: new Date().getTime()
    }
});

const initialState: any = {
    user: null,
    token: null,
    tenant: null,
    order: initialOrder()
};
const AppContext = React.createContext(initialState);
export { AppContext };

const AppContextProvider: React.FC<any> = ({ children }) => {

    let [state, dispatch] = useReducer((state: any, action: any) => {
        switch (action.type) {
            case 'SET_AUTH':
                return {
                    ...state,
                    token: action.payload.token,
                    user: action.payload.user
                };
            case 'REMOVE_AUTH':
                return {
                    ...state,
                    token: null,
                    user: null
                };
            case 'SET_USER':
                return {
                    ...state,
                    user: action.payload.user,
                };
            case 'REMOVE_USER':
                return {
                    ...state,
                    user: null
                };
            case 'SET_TOKEN':
                return {
                    ...state,
                    token: action.payload
                };
            case 'REMOVE_TOKEN':
                return {
                    ...state,
                    token: null
                };
            case 'SET_TENANT':
                return {
                    ...state,
                    tenant: action.payload
                };
            case 'SET_ORDER':
                return {
                    ...state,
                    order: action.payload
                };
            case 'REMOVE_ORDER':
                return {
                    ...state,
                    order: { ...initialOrder(), mode: state.tenant.available_modes[0] }
                };
        };
    }, initialState);

    useEffect(() => {
        checkLocal();
    }, []);

    const checkLocal = async () => {

        let user: any = null;
        let token: any = null;

        Storage.get({ key: 'tenant' }).then((res) => {
            if (res.value) {
                console.log('tenant from storage:', res.value)
                dispatch({ 'type': 'SET_TENANT', 'payload': JSON.parse(res.value) });
            }
        });

        Storage.get({ key: 'token' }).then((ret) => {
            if (ret.value) {
                token = ret.value;
                updateUser(token);
            }
            Storage.get({ key: 'user' }).then((ret2) => {
                if (ret2.value) { user = JSON.parse(ret2.value); }
                dispatch({ 'type': 'SET_AUTH', 'payload': { token: token, user: user } });
            });
        });

        updateTenant();
    }

    const updateUser = async (token: any) => {

        UserService.get().then((data: any) => {
            console.log('updating user:', data);
            dispatch({ 'type': 'SET_AUTH', 'payload': { token: token, user: data } });
            storeUser(data);
        });
    }

    const updateTenant = async () => {
        InitService.getInit().then((data: any) => {
            console.log('updating tenant:', data);
            dispatch({ 'type': 'SET_TENANT', 'payload': data });
            storeTenant(data);
        });
    }

    const storeUser = async (data: User) => {
        Storage.set({
            key: 'user',
            value: JSON.stringify(data)
        });
    };

    const storeTenant = async (data: any) => {
        Storage.set({
            key: 'tenant',
            value: JSON.stringify(data)
        });
    };

    return (
        <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
    );
}

export { AppContextProvider };
