import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { adjustPriceFormat } from '../../../../helpers';
import ProductComponentOptionService from '../../../../shared/services/ProductComponentOptionService';

interface ItemProps {
    component?: ProductComponent
    componentOption?: any,
    onComponentOptionSaved: Function,
    cancelAction: Function
}

export const ComponentOptionEditor: React.FC<ItemProps> = ({ component, componentOption, onComponentOptionSaved, cancelAction }) => {

    console.log('componentOption:', componentOption);

    const component_id = component?.id;
    const newComponentOption = (): any => {
        const emptyComponent: any =
        {
            name: "",
            description: "",
            price: 0,
            default: false,
            product_component_id: component_id
        };

        return emptyComponent;
    }

    const [componentOptionObject, setComponentOptionObject] = useState<any>(componentOption ? { ...componentOption, price: adjustPriceFormat(componentOption.price.amount) } : newComponentOption());
    const [validForm, setValidForm] = useState<boolean>(false);

    const updateComponent = (field: string, value: string | number | undefined | null) => {
        let prod: any = componentOptionObject; prod[field] = value;
        checkFields();
        setComponentOptionObject(prod);

    }
    const checkFields = () => {
        let valid = true;
        //if () valid = false;
        setValidForm(valid);
        return valid;
    }
    const submit = () => {
        if (checkFields()) {
            //confirmAction(componentOptionObject);
        }

        componentOptionObject.price *= 100;

        if (componentOptionObject.id) {
            ProductComponentOptionService.update(componentOptionObject.id, componentOptionObject).then((res) => { console.log(res); onComponentOptionSaved(res); });

        }
        else {
            ProductComponentOptionService.create(componentOptionObject).then((res) => { console.log(res); onComponentOptionSaved(res); });
        }
    }

    useEffect(() => {

    }, []);


    return (
        <>
            <IonContent>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Nuovo componente</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => cancelAction()}>Annulla</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonItem>
                    <IonLabel position="stacked">Nome</IonLabel>
                    <IonInput value={componentOptionObject.name} onIonChange={(e) => updateComponent('name', e.detail.value)} type="text"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Descrizione</IonLabel>
                    <IonInput value={componentOptionObject.description} onIonChange={(e) => updateComponent('description', e.detail.value)} type="text"></IonInput>
                </IonItem>
                {(component?.multiple && <IonItem>
                    <IonLabel position="stacked">Preselezionato</IonLabel>
                    <IonSelect value={componentOptionObject.default} onIonChange={(e) => updateComponent('default', e.detail.value)}>
                        <IonSelectOption value={0}>No</IonSelectOption>
                        <IonSelectOption value={1}>Sì</IonSelectOption>
                    </IonSelect>
                </IonItem>)}
                <IonItem>
                    <IonLabel position="stacked">Prezzo (€)</IonLabel>
                    <IonInput value={componentOptionObject.price} onIonChange={(e) => updateComponent('price', e.detail.value)} type="number" min="0"></IonInput>
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonButton disabled={!validForm} className="ion-margin-bottom" expand="block" onClick={() => { submit(); }}>Salva componente</IonButton>
            </IonFooter>
        </>
    );
};