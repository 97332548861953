import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { printOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AppContext } from '../../../AppContextProvider';
import OrderDetailCard from '../../../components/Order/OrderDetailCard/OrderDetailCard';
import OrderDetailItem from '../../../components/Order/OrderDetailItem/OrderDetailItem';
import OrderUserDetailCard from '../../../components/Order/OrderUserDetailCard/OrderUserDetailCard';
import useInterval from '../../../shared/hooks/useInterval';
import Printer from '../../../shared/printer';
import OrderService from '../../../shared/services/OrderService';
// import { useParams } from 'react-router';
import './OrderDetailPage.css';
import moment from 'moment';

const OrderDetailPage: React.FC = () => {

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const { id } = useParams<{ id: string; }>();
    const [order, setOrder] = useState<Order>();

    const loadOrder = () => {
        OrderService.get(id).then((data) => {
            setOrder(data);
        });
    };

    const updateOrderStatus = (status: string) => {

        if (order)
            OrderService.update(order?.id, { order_status_id: status }).then((data) => {
                setOrder(data);
            });

    }

    useInterval(() => {
        loadOrder();
    }, 60000);

    useEffect(() => {
        loadOrder();
    }, [id]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle>Ordine #{order?.id}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => Printer.printOrder(moment(order?.datetime).format("HH:mm") + ' #' + order?.id)}><IonIcon slot="start" icon={printOutline} /> Stampa</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Ordine #{order?.id}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonToolbar className={"item-with-status " + order?.status?.type.key}>
                    <IonButtons slot="start">
                        <IonLabel>Stato dell'ordine</IonLabel>
                        <IonSelect onIonChange={(e) => updateOrderStatus(e.detail.value)} value={order?.status?.id}>
                            {(
                                state.tenant.order_statuses.map((status: any) => <IonSelectOption key={status.id} value={status.id}>
                                    {status.name}
                                </IonSelectOption>
                                )
                            )}

                        </IonSelect>
                    </IonButtons>
                </IonToolbar>
                <IonGrid>
                    <IonRow>
                        <IonCol sizeLg="6">
                            <OrderDetailCard order={order} />
                        </IonCol>
                        {(order?.source !== 0 && order?.source !== 1) &&
                            <IonCol sizeLg="6">
                                <OrderUserDetailCard user={order?.user} />
                            </IonCol>}
                    </IonRow>
                </IonGrid>
                <IonList>
                    <IonListHeader >Prodotti dell'ordine</IonListHeader>
                    {order?.items.map((item: OrderItem) =>
                        <OrderDetailItem key={item.id} item={item}></OrderDetailItem>
                    )}
                    {order?.notes && (
                        <>
                            <IonListHeader>Note</IonListHeader>
                            <IonItem>
                                <p>{order?.notes}</p>
                            </IonItem>
                        </>)}
                </IonList>
                <div id="printable" style={{ display: 'none' }}>
                    {(order && order !== undefined && order.kitchen_category_groups) && Object.keys(order?.kitchen_category_groups).map((key: string) =>
                        <>
                            <h2>{key}</h2>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Qt.</th>
                                        <th>Prod.</th>
                                    </tr>
                                    {order?.kitchen_category_groups[key].map((item: any) =>
                                        <tr key={item.id}>
                                            <td>
                                                x{item.quantity}
                                            </td>
                                            <td>{item.product?.name}
                                                <ul>
                                                    {item.component_strings.map((component: any) => <li key={component} className="item-options">{component}</li>)}
                                                </ul>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </>
                    )}
                    {order?.notes && (
                        <>
                            <h3>Note: </h3>
                            <p>{order?.notes}</p>
                        </>)
                    }
                </div>

            </IonContent>
        </IonPage >
    );
};

export default OrderDetailPage;
