import request from '../request'


function getInit() {
    return request({
        url: `init`,
        method: 'GET'
    });
}


const InitService = { getInit };
export default InitService;