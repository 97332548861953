import React, { useEffect } from 'react';
import { IonButton, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonFooter } from '@ionic/react';
import { useState } from 'react';
import ProductOptionSelector from './ComposeProduct/ProductOptionsSelector';
import { componentsTotal, countOrderItem, formattedPrice, hashItem, optionsFromCart } from '../../../helpers';


interface ContainerProps {
  item: any,
  confirmAction: Function,
  cancelAction: Function
}

const ComponibleModal: React.FC<ContainerProps> = ({ item, confirmAction, cancelAction }) => {

  const [updatedItem, setUpdatedItem] = useState(item);
  useEffect(() => {
    console.log('initial item:', item);
  },
    [updatedItem]);

  const updateItem = (cart: any) => {

    item.hash = hashItem(item);
    item.total = Number(item.product.price.amount) + componentsTotal(cart);
    item.product_components = cart;
    item.product_component_options = optionsFromCart(cart);
    setUpdatedItem(item);
  };




  return (
    <>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Scegli le opzioni</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={() => cancelAction()}>
                Annulla
            </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <ProductOptionSelector updateCart={(cart: any) => { updateItem(cart); }} components={item.product.components}></ProductOptionSelector>
      </IonContent>
      <IonFooter>
        <IonButton  className="ion-margin-bottom" expand="block" onClick={() => { confirmAction(updatedItem); }}>Aggiungi al carrello</IonButton>
      </IonFooter>
    </>
  );
};

export default ComponibleModal;