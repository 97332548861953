import React, { useEffect } from 'react';
import { IonRow, IonCol, IonText, IonButton, IonHeader, IonToolbar, IonTitle, IonButtons, IonPage } from '@ionic/react';
import { useState } from 'react';
import { hashItem } from '../../../helpers';


interface ContainerProps {
    item: any,
    confirmAction: Function,
    closeAction: Function
}

const QuantityModal: React.FC<ContainerProps> = ({ item, confirmAction, closeAction }) => {

    const [itemQuantity, setItemQuantity] = useState(item.quantity);

    const updateQuantity = (mode: 'more' | 'less') => {
        switch (mode) {
            case 'more':
                setItemQuantity(itemQuantity + 1);
                break;
            case 'less':
                if (itemQuantity > 0) setItemQuantity(itemQuantity - 1);
                break;
        }
    }

    const saveAndExit = () => {
        item.quantity = itemQuantity;
        item.total = item.product.price.amount * item.quantity;
        item.hash = hashItem(item);
        confirmAction(item);
    };

    useEffect(() => {
        console.log('updated: ', itemQuantity);
    }, [itemQuantity])

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Seleziona quantità
                </IonTitle>
                    <IonButtons>
                        <IonButton onClick={() => closeAction()} >
                            Annulla
                    </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonRow className="ion-padding">
                <IonCol className="ion-text-center"> <IonButton onClick={() => updateQuantity('less')} color="light" className="quantity-button">-</IonButton></IonCol>
                <IonCol className="ion-text-center">
                    <IonText color="dark"><h2>{itemQuantity}</h2></IonText>
                </IonCol>
                <IonCol className="ion-text-center"> <IonButton onClick={() => updateQuantity('more')} color="light" className="quantity-button">+</IonButton></IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonButton className="ion-margin-bottom" expand="block" onClick={saveAndExit}>Aggiorna carrello</IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default QuantityModal;
