import React, { useContext, useEffect } from 'react';
import { IonButton, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonNote, IonIcon, IonToggle, IonList, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonText } from '@ionic/react';
import { useState } from 'react';
import { addCircle, alertCircle, helpCircleSharp, informationCircleOutline, listCircle, pencil, trash } from 'ionicons/icons';
import ImagePlaceholder from '../../../Placeholders/ImagePlaceholder/ImagePlaceholder';
import { ComponentItem } from './ComponentItem';


interface ContainerProps {
    components: ProductComponent[],
    showEditorAction: Function,
    showOptionEditorAction: Function,
    onDelete: Function
}

const ProductComponentEditor: React.FC<ContainerProps> = ({ components, showEditorAction, showOptionEditorAction, onDelete }) => {

    useEffect(() => {

    }, []);


    return (
        <IonList>
            <IonToolbar>
                <IonButtons onClick={() => showEditorAction()} className="ion-text-center ion-justify-content-center" slot="end">
                    <IonButton><IonIcon slot="start" icon={addCircle} />Aggiungi componente</IonButton>
                </IonButtons>
            </IonToolbar>
            {(components.length === 0 && <ImagePlaceholder text="" image="customization"></ImagePlaceholder>)}
            {components.map((component) =>
                <ComponentItem onDelete={onDelete} key={component.id} editComponentAction={(component: ProductComponent) => showEditorAction(component)} editComponentOptionAction={(option: ProductComponentOption) => showOptionEditorAction(option, component)} component={component} />)}
        </IonList>
    );
};

export default ProductComponentEditor;