import React from 'react';
import { IonItem, IonButton, IonButtons, IonIcon, IonToolbar, IonLabel, IonSelect, IonSelectOption, IonToggle, IonCol, IonGrid, IonRow } from '@ionic/react';
import './CatalogPageToolbar.css';
import { add } from 'ionicons/icons';


interface ContainerProps {
    currentParams: any,
    onCreateButtonClick: Function,
    onActiveToggleChange: Function,
    onCategoryChange: Function,
    filterableCategories: []
}

const CatalogPageToolbar: React.FC<ContainerProps> = ({ currentParams, onCreateButtonClick, filterableCategories, onActiveToggleChange, onCategoryChange }) => {

    const updateStatusParam = (value: boolean) => {
        console.log('switch value:', value);
        switch (value) {
            case true:
                onActiveToggleChange('active');
                break;

            case false:
                onActiveToggleChange(null);
                break;
        }
    }

    return (
        <IonToolbar>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonSelect value={currentParams.category_id} onIonChange={(e) => onCategoryChange(e.detail.value)}>
                            <IonSelectOption value={null}>Tutte le categorie</IonSelectOption>
                            {(filterableCategories.map((category: any) =>
                                <IonSelectOption key={category.id} value={category.id}>{category.name}</IonSelectOption>)
                            )}
                        </IonSelect>
                    </IonCol>
                    <IonCol>
                        <IonItem lines="none">
                            <IonLabel>Solo attivi</IonLabel>
                            <IonToggle onIonChange={(e) => updateStatusParam(e.detail.checked)} checked={currentParams.status === 'active'}></IonToggle>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>

            <IonButtons slot="end">
                <IonButton onClick={() => onCreateButtonClick()}>
                    <IonIcon slot="end" icon={add} />
                            Crea nuovo
                        </IonButton>
            </IonButtons>
        </IonToolbar>
    );
};

export default CatalogPageToolbar;
