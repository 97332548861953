import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { LoginComponent } from '../../../components/Login/LoginComponent';
// import { useParams } from 'react-router';
import './LoginPage.css';
import { useHistory } from "react-router-dom";

const LoginPage: React.FC = () => {

    // const { name } = useParams<{ name: string; }>();
    const history = useHistory();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Effettua l'accesso</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Effettua l'accesso</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow class="ion-justify-content-center">
                        <IonCol size="12" sizeLg="6">
                            <LoginComponent onAccess={() => {history.push('/');}}></LoginComponent>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default LoginPage;
