import { IonButton, IonButtons, IonIcon, IonItem, IonReorder } from '@ionic/react';
import { pencil, trash } from 'ionicons/icons';
import React from 'react';

interface ItemProps {
    category: Category,
    deleteAction: Function,
    editAction: Function
}

export const CategoryItem: React.FC<ItemProps> = ({ category, deleteAction, editAction }) => {

    return (
        <IonItem>
            {category.name}
            <IonButtons slot="end">
                <IonButton onClick={() => editAction(category)}><IonIcon icon={pencil} /></IonButton>
                <IonButton onClick={() => deleteAction(category)} color="danger"><IonIcon icon={trash} /></IonButton>
            </IonButtons>
            <IonReorder slot="end" />
        </IonItem>
    );
};