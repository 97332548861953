import React from 'react';
import { IonItem, IonText, IonNote } from '@ionic/react';
import './CartItem.css';

interface ContainerProps {
    item: any
}

const CartItem: React.FC<ContainerProps> = ({ item }) => {

    const optionsFromComponent = (component: any) => {

        return (component.selectedOptions.length > 0) ? component.selectedOptions.map((o: any) => o.name).join(',') : 'Nessuno';
    }

    const componentsFromItem = (components: any) => {
        return components.map((c: any) => c.name + ': ' + optionsFromComponent(c)).join(', ');
    }

    return (
        <IonItem key={item.hash}>
            <IonText>
                <p>{item.product?.name}</p>
                <p className="item-options">{(item.product_components) && componentsFromItem(item.product_components)}</p>
                <p className="item-price-text">{item.product.price.formatted} al pz.</p>
            </IonText>
            <IonNote slot="end">x{item.quantity}</IonNote>
        </IonItem>
    );
};

export default CartItem;
