import { IonInfiniteScroll, IonInfiniteScrollContent, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonModal, useIonViewDidEnter, IonProgressBar } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import ProductService from '../../../shared/services/ProductService';
import './ProductsPage.css';
import { useHistory } from "react-router-dom";
import { ProductList } from '../../../components/Catalog/ProductList/ProductList';
import CatalogPageToolbar from '../../../components/Catalog/CatalogPageToolbar/CatalogPageToolbar';
import { AppContext } from '../../../AppContextProvider';
import NewProductModal from '../../../components/Product/NewProduct/NewProductModal';
import Skeleton from '../../../components/Placeholders/Skeleton/Skeleton';

const ProductsPage: React.FC = () => {

    const globalState = useContext(AppContext);
    const { state } = globalState;

    const history = useHistory();
    const [products, setProducts] = useState<Product[]>([]);
    const [searchParams, setSearchParams] = useState({});
    const [productsLoaded, setProductsLoaded] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>();
    const [infiniteLoading, setInfiniteLoading] = useState<boolean>(false);
    const [showNewProductModal, setShowNewProductModal] = useState<boolean>(false);
    const [firstLoadComplete, setFirstLoadComplete] = useState<boolean>(false);

    const loadProducts = () => {
        setProductsLoaded(false);
        ProductService.list(searchParams).then((data: any) => {
            setProducts((infiniteLoading) ? products.concat(data.data) : data.data);
            setLastPage(data.meta.last_page);
            setCurrentPage(data.meta.current_page);
            setProductsLoaded(true);
            setInfiniteLoading(false);
            setFirstLoadComplete(true);
        });
    };

    const createProduct = (newProduct: any) => {
        ProductService.create(newProduct).then((res) => {
            setShowNewProductModal(false);
            history.push('/product/' + res.data.id);
        })
    }


    const init = () => {
        if (!searchParams.hasOwnProperty('page')) { setSearchParams(Object({ page: 1, per_page: 15, category_id: null, status: 'active', order_by: null })); }
        else {
            loadProducts();
        }
    };

    useEffect(() => {
        //console.log('effect applied');
    }, [products, productsLoaded]);


    useEffect(() => {
        loadProducts();
    }, [searchParams]);

    useIonViewDidEnter(() => init(), []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Prodotti</IonTitle>
                </IonToolbar>
                <CatalogPageToolbar
                    currentParams={searchParams}
                    onActiveToggleChange={(value: string) => setSearchParams({ ...searchParams, status: value, page: 1 })}
                    filterableCategories={state.tenant.categories}
                    onCreateButtonClick={() => setShowNewProductModal(true)}
                    onCategoryChange={(category_id: number) => setSearchParams({ ...searchParams, category_id: category_id, page: 1 })} />
            </IonHeader>

            <IonContent fullscreen>
                {(!productsLoaded && firstLoadComplete) && (<IonProgressBar type="indeterminate"></IonProgressBar>)}
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Prodotti</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {!firstLoadComplete && (<Skeleton></Skeleton>)}
                <ProductList products={products}></ProductList>
                <IonInfiniteScroll disabled={(currentPage === lastPage)} threshold="100px" onIonInfinite={(e: any) => { setInfiniteLoading(true); setSearchParams({ ...searchParams, page: currentPage + 1 }); setTimeout(() => { e.target.complete() }, 3000); }}>
                    <IonInfiniteScrollContent loadingSpinner="circular" loadingText="Carico altri..."></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>

            <IonModal onDidDismiss={() => setShowNewProductModal(false)} isOpen={showNewProductModal}>
                <NewProductModal confirmAction={(newProduct: any) => createProduct(newProduct)} cancelAction={() => setShowNewProductModal(false)} />
            </IonModal>

        </IonPage>
    );
};

export default ProductsPage;
