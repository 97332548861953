import { IonList } from '@ionic/react';
import React from 'react';
import { AppContext } from '../../../AppContextProvider';
import { OrderItem } from '../OrderItem/OrderItem';


interface ItemProps {
    orders: Order[],
    onSelection?: Function
}

export const OrderList: React.FC<ItemProps> = ({ orders, onSelection }) => {


    return (
        <IonList onSelect={order => onSelection ? onSelection(order) : {}} lines="full">
            {orders.map(order => <OrderItem key={order.id} order={order}></OrderItem>)}
        </IonList>
    );
};